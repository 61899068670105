import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import Select from "react-select";
import {useGetCompaniesQuery, useGetSectorsQuery, useGetThemesQuery} from "../../../services/company";

const SectorListPage = () => {

  const {data: dataSectors} = useGetSectorsQuery()
  const [optionSectors, setOptionSectors] = useState([])

  useEffect(() => {
    if (!dataSectors) return
    const options = []
    for (const result of dataSectors?.results) {
      options.push({
        'value': result.id,
        'label': result.name,
      })
    }
    setOptionSectors(options)
  }, [dataSectors]);


  const [selectedSector, setSelectedSector] = useState(null);

  return (
      <>
        <Row>
          <Col xl={2}>
            <Card>
              <Card.Header>
                <Card.Title><h3>필터</h3></Card.Title>
              </Card.Header>
              <Card.Body>
                <p className="mb-1">업종</p>
                {dataSectors?.results && <Select
                    defaultValue={selectedSector}
                    onChange={setSelectedSector}
                    options={optionSectors}
                />}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                마켓 타이밍 전략 : 코스닥지수의 3, 5, 10일 이동평균을 계산한 후, 코스닥 지수가 이 3개 이동평균보다 낮을 경우 투자를 중단 (MDD를 엄청 줄일 수 있다)
              </Card.Body>
            </Card>
          </Col>
          <Col xl={10}>
            {/*<CompanyList/>*/}
          </Col>
        </Row>
      </>
  )
}
export default SectorListPage;