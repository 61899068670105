const DebtRatio = ({stock}) => {
  const f = stock.finance
  const debtRatio = f?.debtRatio

  return <>
    <span className={`${debtRatio < 80 ? 'text-info' : 'text-danger'}`}>{debtRatio?.toFixed(2)}</span>
  </>
}


export default DebtRatio
