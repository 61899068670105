const PER = ({stock}) => {
  const per = stock?.per
  const rank = stock?.rank

  const Data = () => {
    const text = per?.toFixed(2)
    if (3 <= per && per <= 10) {
      return <span className="text-info">{text}</span>
    } else if (per < 0) {
      return <span className="text-warning">{text}</span>
    } else if (per > 10 || per < 3) {
      return <span className="text-danger">{text}</span>
    } else {
      return <span>{text}</span>
    }
  }

  const Rank = () => {
    if (rank?.per?.rank) {
      return <small> ({rank?.per?.rank}위, <span className={`${rank?.per?.rankRatio <= 20 ? 'text-info' : ''}`}>{rank?.per?.rankRatio?.toFixed(2)}%</span>)</small>
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>

    {rank?.perFinance?.value && <span className="ms-2"> (분기 {rank?.perFinance?.value?.toFixed(2)},
      <span> {rank?.perFinance?.rank}위, <span className={`${rank?.perFinance?.rankRatio <= 20 ? 'text-info' : ''}`}>{rank?.perFinance?.rankRatio?.toFixed(2)}%</span></span>
      )
    </span>}
  </>
}

export default PER
