export const Colors = {
  primary: '#886cc0',
  secondary: '#2896f3',
  red: '#F55150',
  maroon: '#880d4f',
  blue: '#2896f3',
  yellow: '#feeb3b',
  orange: '#f89804',
  lime: '#4fe675',
  purple: '#2896f3',
  teal: '#2a897b',
  gray: '#787b86',
  aqua: '#3bbcd4',
  white: '#ffffff',
  black: '#363a45',
  silver: '#b2b5be',
  navy: '#311b92',
  green: '#4caf50',
  organizationAndForeigner: '#4caf50',
  organization: '#3bbcd4',
  foreigner: '#2a897b',
}