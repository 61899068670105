import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const stockApi = createApi({
  reducerPath: 'stockApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // prepareHeaders: (headers, {getState}) => {
    //   const accessToken = getState().user.accessToken
    //   if (accessToken) {
    //     headers.set('Authorization', `Bearer ${accessToken}`)
    //   }
    //   return headers
    // },
  }),
  tagTypes: ['Stock'],
  // refetchOnMountOrArgChange: 1,
  endpoints: (build) => ({
    getStocks: build.query({
      query: ({type, sector, company, market, indexType, date, dateRange, pageSize}) => {
        console.log('getCompanies')
        return {
          url: `exchanges/KRX/stocks/`,
          params: {
            type: type ? type : '',
            sector: sector ? sector : '',
            company: company ? company : '',
            market: market ? market : '',
            index_type: indexType ? indexType : '',
            date: date ? date : '',
            date__range: dateRange ? dateRange : '',
            page_size: pageSize ? pageSize : '224',
          }
        }
      }
    }),
    getCompanyStocks: build.query({
      query: ({date, sector, company, theme, companyCode, companyType, stockType, search, page, ordering}) => {
        console.log('getDetailCompanies', sector, company)
        return {
          url: `stocks/`,
          params: {
            date: date ?? '',
            page_size: 30,
            page: page ?? 1,
            sectors: sector ?? '',
            company: company ?? '',
            theme: theme ?? '',
            company__code: companyCode ?? '',
            company__type: companyType ?? 'basic',
            stock_types: stockType ?? '',
            search: search ?? '',
            ordering: ordering ?? '',
          }
        }
      }
    }),
  }),
})

export const {
  useGetStocksQuery,
  useGetCompanyStocksQuery,
} = stockApi