import React from "react";
import {Bar, Line} from "react-chartjs-2";
import _ from 'lodash'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PrimaryColor, {Colors} from "../../../../utils/Color";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const StockChart = ({stocks}) => {
  const filtered = stocks.filter((stock) => stock.date);
  const ordered = _.orderBy(filtered, ['date'], ['asc'])
  const data = {
    defaultFontFamily: "Poppins",
    labels: ordered.map(({date}) => date),
    datasets: [
      {
        label: "주가차트",
        data: ordered.map(({close}) => close),
        borderColor: Colors.black,
        borderWidth: 2,
        // backgroundColor: "#886cc0",
        backgroundColor: Colors.black,
        pointRadius: 0,
      },
      {
        label: "20일 이동평균선",
        data: ordered.map(({sma20}) => sma20),
        borderColor: Colors.yellow,
        borderWidth: 2,
        backgroundColor: Colors.yellow,
        pointRadius: 0,
      },
      {
        label: "60일 이동평균선",
        data: ordered.map(({sma60}) => sma60),
        borderColor: Colors.orange,
        borderWidth: 2,
        backgroundColor: Colors.orange,
        pointRadius: 0,
      },
      {
        label: "224일 이동평균선",
        data: ordered.map(({sma224}) => sma224),
        borderColor: Colors.gray,
        borderWidth: 2,
        backgroundColor: Colors.gray,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    legend: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    layout: {
      padding: {
        right: 0
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      // tooltip: {
      //   callbacks: {
      //     footer: (items) => {
      //       return 'ddd'
      //     },
      //   }
      // }
    },
    // scales: {
    //   yAxes: [
    //     {
    //       type: 'linear',
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    //   xAxes: [
    //     {
    //       type: 'linear',
    //       barPercentage: 0.5,
    //     },
    //   ],
    // },
  };

  return (
      <>
        <Line options={options} data={data} height={150}/>
      </>
  );
}

export default StockChart;
