const PSR = ({stock}) => {
  const psr = stock?.psr
  const psrRank = stock?.rank?.psr
  const psrFinanceRank = stock?.rank?.psrFinance

  const Data = () => {
    const text = psr?.toFixed(2)
    if (psr < 0.35) {
      return <span className="text-info">{text}</span>
    } else if (psr >= 3.2) {
      return <span className="text-danger">{text}</span>
    } else {
      return <span>{text}</span>
    }
  }

  const Rank = () => {
    if (psrRank?.rank) {
      return <small>({psrRank?.rank}위, {psrRank?.rankRatio.toFixed(2)}%)</small>
    }
    return <></>
  }

  const PsrFinance = () => {
    if (psrFinanceRank?.value) {
      return <span>
        (분기 {psrFinanceRank?.value?.toFixed(2)}
        <small> ({psrFinanceRank.rank}위, <span className={`${psrFinanceRank.rankRatio <= 20 ? 'text-info' : ''}`}>{psrFinanceRank.rankRatio.toFixed(2)}%</span>)</small>)
      </span>
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>
    <PsrFinance/>
  </>
}


export default PSR
