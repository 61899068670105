const ROE = ({stock}) => {
  const f = stock?.finance
  const roe = f?.roe

  const Data = () => {
    const text = roe?.toFixed(2)
    if (roe < 3) {
      return <span className="text-danger">{text}</span>
    } else if (roe >= 10) {
      return <span className="text-info">{text}</span>
    }
    return <span>{text}</span>
  }

  return <>
    <Data/>
  </>
}

export default ROE
