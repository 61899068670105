import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  index: null,
  sector: null,
  company: null,
};

export const selectedSlice = createSlice({
  initialState,
  name: 'selectedSlice',
  reducers: {
    reset: () => initialState,
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setSector: (state, action) => {
      state.sector = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
  },
});

export default selectedSlice.reducer;

export const {reset, setIndex, setSector, setCompany} = selectedSlice.actions;

export const selectIndex = (state) => state.selected.index
export const selectSector = (state) => state.selected.sector
export const selectCompany = (state) => state.selected.company