import {useNavigate, useParams} from "react-router-dom";
import CompanyChart from "./partials/CompanyChart";
import React, {useEffect, useState} from "react";
import {useGetCompaniesQuery, useGetCompanyQuery, useGetSectorsQuery} from "../../../services/company";
import {selectCompany} from "../../../store/features/selectedSlice";
import {useSelector} from "react-redux";
import Select from "react-select";
import {Button, Card, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";

const CompanyDetailPage = () => {
  const navigate = useNavigate();
  let {companyCode} = useParams()
  // const company = useSelector(selectCompany)
  const [code, setCode] = useState(null);

  const {data: dataCompanies} = useGetCompaniesQuery()
  const {data: dataCompany} = useGetCompanyQuery({companyCode: code}, {skip: code === null,})
  const [optionCompanies, setOptionCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (!dataCompanies) return
    const options = []
    for (const result of dataCompanies?.results) {
      const option = {
        'value': result.id,
        'label': `${result.name} (${result.code})`,
        'code': result.code,
      }
      options.push(option)
      if (companyCode === result.code) {
        setSelectedCompany(option)
      }
    }
    setOptionCompanies(options)
  }, [dataCompanies]);

  useEffect(() => {
    setCode(companyCode)
  }, [companyCode]);

  // useEffect(() => {
  //   console.log('company', company)
  // }, [company]);

  useEffect(() => {
    console.log('selectedCompany', selectedCompany)
    if (selectedCompany) {
      setCode(selectedCompany.code)
      console.log('code', code)
    }

  }, [selectedCompany]);

  const CompanySelector = () => {
    return <div style={{width: '250px'}} className="ps-2">
      {dataCompanies?.results && <Select
          defaultValue={selectedCompany}
          onChange={setSelectedCompany}
          options={optionCompanies}
      />}
    </div>
  }
  const CompanyTitle = ({company}) => {
    return <span className="text-black">{company.name} <small className="text-muted">({company.code})</small></span>
  }

  return <>
    <PageTitle activeMenu={dataCompany && <CompanyTitle company={dataCompany}/>} motherMenu="종목"
               pageContent={<CompanySelector/>}/>

    {dataCompany && <CompanyChart company={dataCompany}/>}
  </>
}

export default CompanyDetailPage