import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // prepareHeaders: (headers, {getState}) => {
    //   const accessToken = getState().user.accessToken
    //   if (accessToken) {
    //     headers.set('Authorization', `Bearer ${accessToken}`)
    //   }
    //   return headers
    // },
  }),
  tagTypes: ['Company', 'DetailCompany'],
  // refetchOnMountOrArgChange: 1,
  endpoints: (build) => ({
    getSectors: build.query({
      query: () => {
        return {
          url: `sectors/`,
          params: {
            page_size: 100,
            type: 'small',
          }
        }
      }
    }),
    getSector: build.query({
      query: ({sectorCode}) => `sectors/${sectorCode}/`,
    }),
    getThemes: build.query({
      query: () => {
        return {
          url: `themes/`,
          params: {
            page_size: 100,
          }
        }
      }
    }),
    getCompanies: build.query({
      query: () => {
        console.log('getCompanies')
        return {
          url: `companies/`,
          params: {
            page_size: 3000,
          }
        }
      }
    }),
    getCompany: build.query({
      query: ({companyCode}) => `companies/${companyCode}/`,
    }),
    getDetailCompanies: build.query({
      query: ({sector, company, theme, code, stockType, search, page, ordering}) => {
        console.log('getDetailCompanies', sector, company)
        return {
          url: `companies/detail/`,
          params: {
            page_size: 30,
            sectors: sector ? sector : '',
            company: company ? company : '',
            theme: theme ? theme : '',
            code: code ? code : '',
            stock_types: stockType ? stockType : '',
            search: search ? search : '',
            page: page ? page : 1,
            ordering: ordering ? ordering : '',
          }
        }
      }
    }),
  }),
})

export const {
  useGetSectorsQuery,
  useGetSectorQuery,
  useGetThemesQuery,
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetDetailCompaniesQuery,
} = companyApi