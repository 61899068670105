const OperatingProfit = ({stock}) => {
  const f = stock.finance
  const r = stock?.rank

  const HigherThan100 = ({value}) => {
    const text = value?.toFixed(2)
    if (value > 50) {
      return <span className="text-info">{text}% </span>
    } else if (value < 0) {
      return <span className="text-danger">{text}% </span>
    }
    return <span>{text}% </span>
  }

  return <>
    {f && f?.type === 'quarter' && <>
      <p className="mb-0 fs-14">
        <HigherThan100 value={f?.operatingProfitGrowthComparedToPreviousQuarter}/>
        <small>(전기, {r?.opgPq?.rank}위, {r?.opgPq?.rankRatio?.toFixed(2)}%) </small>
        <br/>
        <HigherThan100 value={f?.operatingProfitGrowthComparedToSamePeriodLastYear}/>
        <small>(작년동기, {r?.opgLy?.rank}위, {r?.opgLy?.rankRatio?.toFixed(2)}%)</small>
      </p>
    </>}
  </>
}

export default OperatingProfit

// {% if value > 50 %}
//     <span class="text-info">{{ value|floatformat:2 }}%</span>
// {% else %}
//     {% if value < 0 %}
//         <span class="text-danger">{{ value|floatformat:2 }}%</span>
//     {% else %}
//         {{ value|floatformat:2 }}%
//     {% endif %}
// {% endif %}

// {% if f.type == 'quarter' %}
//
//     <small>전기</small>, <small>작년동기</small><br>
//     {% include 'apps/companies/partials/content/higher_than_100.html' with value=f.operating_profit_growth_compared_to_previous_quarter %}
//     {% include 'apps/companies/partials/content/higher_than_100.html' with value=f.operating_profit_growth_compared_to_same_period_last_year %}
//
//     <br>
//     <small>
//         ({ r.opg_pq_rank }위, { r.opg_pq_rank_ratio|floatformat:2 }%)
//         ({ r.opg_ly_rank }위, { r.opg_ly_rank_ratio|floatformat:2 }%)
//     </small>
//
//
// {% endif %}