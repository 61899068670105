import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import StockChart from "../chart/StockChart";
import {useGetStocksQuery} from "../../../../services/stock";
import SupplyChart from "../chart/SupplyChart";
import TurtleChart from "../chart/TurtleChart";
import TurtleTable from "../chart/TurtleTable";
import CompanyAndSectorChart from "../chart/CompanyAndSectorChart";
import CompanyAndSectorCorrelationChart from "../chart/CompanyAndSectorCorrelationChart";
import VolumeChart from "../chart/VolumeChart";
import AtrChart from "../chart/AtrChart";
import MyCard from "../../../../utils/MyCard"

const CompanyChart = ({company}) => {
  const [sectorId, setSectorId] = useState(null)
  const [stocks, setStocks] = useState(null)
  const [sectorStocks, setSectorStocks] = useState(null)
  const {data} = useGetStocksQuery({company: company.id})
  const {data: dataSectorStocks} = useGetStocksQuery({sector: sectorId, market: 'all'}, {skip: sectorId === null,})

  useEffect(() => {
    console.log('setSector', company.sectors[0].id)
    setSectorId(company.sectors[0].id)
  }, [company])


  useEffect(() => {
    setStocks(data?.results)
  }, [data])

  useEffect(() => {
    setSectorStocks(dataSectorStocks?.results)
  }, [dataSectorStocks])


  return (
      <>
        <Row>
          {/*<Col xl={12}>*/}
          {/*  <h3 className="mb-4">*/}
          {/*    {company?.name} <small className="text-muted fs-14">{company?.code}</small>*/}
          {/*  </h3>*/}
          {/*</Col>*/}
          <Col xl={7}>
            <MyCard title={'주식차트'} content={stocks && <>
              <StockChart stocks={stocks}/>
            </>
            }/>
          </Col>
          <Col xl={5}>
            <MyCard title={'거래량 & 수급차트'} content={stocks && <>
              <VolumeChart stocks={stocks}/>
              <SupplyChart stocks={stocks}/>
            </>}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>터틀 전략</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xl={7}>
                    {stocks && <TurtleChart stocks={stocks}/>}
                  </Col>
                  <Col xl={5}>
                    {stocks && <AtrChart stocks={stocks}/>}
                    <div className="mt-3"></div>
                    {stocks && <TurtleTable stocks={stocks}/>}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>업종과 비교</Card.Title>
              </Card.Header>
              <Card.Body>
                {stocks && sectorStocks && <CompanyAndSectorChart companyStocks={stocks} sectorStocks={sectorStocks}/>}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>업종과 비교</Card.Title>
              </Card.Header>
              <Card.Body>
                {stocks && sectorStocks && <CompanyAndSectorCorrelationChart companyStocks={stocks} sectorStocks={sectorStocks}/>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>ATR(N) 차트</Card.Title>
              </Card.Header>
              <Card.Body>
                {stocks && <AtrChart stocks={stocks}/>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
  )
}
export default CompanyChart;