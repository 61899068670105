import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const TurtleStrategyPage = () => {
  return (
      <Container className="container">
        <Row>
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>터틀 매매 전략</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  적용 시장 : 전체 시장<br/>
                  사용 지표 : 없음<br/>
                  4주채널 전략을 개선<br/>
                  2주 채널을 돌파할 때 손절매를 도입했고, 매매를 개시하기 직전에 손실 신호가 잇어야한다는 필터를 추가했다<br/>
                  변수의 수 2개 : (4주) 진입 채널, (2주) 손절매 채널<br/>
                  변수 대칭성 : 매수와 매도에 동일한 변숫값 사용
                </p>
                <h5>매수 규칙</h5>
                <p>
                  - 예비 신호 : 주간 채널이 직전 4주 고가 채널 중 최고점<br/>
                  - 필터 : 직전 매매에서 손실을 보았을 때만 매매<br/>
                  - 진입 신호 : 직전 4주 고가 채널을 상향 돌파할 때만 매수<br/>
                  - 손절매 : 직전 2주 저가 채널을 하향 돌파할 때 매도<br/>
                </p>
                <h5>매도 규칙</h5>
                <p>
                  - 예비 신호 : 주간 채널이 직전 4주 저가 채널 중 최저점<br/>
                  - 필터 : 직전 매매에서 손실을 보았을 때만 매매<br/>
                  - 진입 신호 : 직전 4주 저가 채널을 하향 돌파할 때 매도<br/>
                  - 손절매 : 직전 2주 고가 채널을 하향 돌파할 때 매수<br/>
                </p>
                이 전략을 기준으로 다른 전략을 판단하자
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>전략 개발</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  - 포트폴리오 구성<br/>
                  - 데이터<br/>
                  - 소프트웨어<br/>
                  - 성공하는 전략의 속성<br/>
                  - 전략 검토<br/>
                  - 벤치마크 전략
                </p>
                <p>
                  1. 매매 기법 찾기<br/>
                  2. 매매 기법 코드화<br/>
                  3. 매매 기법 검토<br/>
                  4. 매매 기법 비교<br/>
                  5. 매매 기법 조정<br/>
                  - 과도한 최적화 피하기<br/>
                  - 다시 검토<br/>
                  - 다시 비교<br/>
                  6. 손익 그래프의 안정성 검토 완료하기
                </p>
                <br/>
                사용되는 규칙, 지표 및 변수의 수를 최소화하려고 노력하자. 그리고 터틀 매매 전략과 비교해보자
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <Card.Header>
                <Card.Title>주봉 다우 전략 (WDT)</Card.Title>
              </Card.Header>
              <Card.Body>
                손실필터, 일봉 최초 손절매 및 주봉 스윙 포인트 추적 손절매를 포함하는 수정된 주봉 다우 전략 (MWDT)이 가장 우수하다고 생각한다.<br/>
                <br/>
                주봉 다우 전략 (WDT)<br/>
                <br/>
                - 핵심 이론 : 다우이론<br/>
                - 규칙수 4개<br/>
                <br/>
                <h5>매수 규칙</h5>
                - 필터 : 주봉 다우 추세의 직전 변화가 손실일 경에만 매매<br/>
                - 예비 신호/진입 신호 : 주봉 다우 추세가 하락에서 상승으로 반전<br/>
                - 최초 손절매 : 예비 신호 또는 진입 신호가 나온 일봉의 최저점을 하향 돌파할 때 매도<br/>
                - 추적 손절매 : 일봉 다우 추세가 상승에서 하락으로 반전<br/>
                <br/>
                <h5>매도 규칙</h5>
                - 필터 : 주봉 다우 추세의 직전 변화가 손실일 경우에만 매매<br/>
                - 예비 신호/진입 신호 : 주봉 다우 추세가 상승에서 하락으로 반전<br/>
                - 최초 손절매 : 예비 신호 또는 진입 신호가 나온 일봉의 최고점을 상향 돌파할 때 매수<br/>
                - 추적 손절매 : 일봉 다우 추세가 하락에서 상승으로 반전
              </Card.Body>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <Card.Header>
                <Card.Title>오리지널 터틀의 규칙</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                "나는 늘 우리의 트레이딩 규칙을 신문에다 공개해도 따라하는 사람은 없을 거라고 말하죠. 중요한 것은 일관성과 자제력이거든요. 우리가 터틀에게 가르친 규엑에 버금가는 규칙을 세울 수 있는 사람은 많을 겁니다. 하지만 그들이 할 수 없는 일은 사오항이 안 좋아졌을 때 자신들이 세운 규칙을 고수하는 것이죠."
</p>
<p>
                - 성공한 트레이더들 대다수는 기계적 트레이딩 시스템을 사용한다.<br/>
                - 자동화함으로써 결정을 내려야 할 때마다 이에 대한 적절한 해답을 제시해준다.<br/>
                - 자신의 판단에만 의존해야 할 때 느끼게 될 부담감에서 벗아날 수 있다.<br/>
                - 철저히 검증된 기계적 시스템만이 제공할 수 있는 신뢰감에 트레이더 자신의 일관성, 자제심이 더해질 때 비로소 성공 트레이딩을 할 수 있다<br/>
                - 터틀 트레이딩 시스템은 완벽한 기계적 트레이딩 시스템이다. 트레이더의 주관적 결정이나 판단을 허용하지 않는다.<br/>
</p>
                ---
    <p>
                시장 : 무엇을 사고, 팔 것인가
                - 트레이딩을 할 시장의 수가 너무 적으면 다양한 시장에서 형성되는 추세를 이용할 기회가 그만큼 줄어든다.<br/>
                - 다만 거래량이 너무 적거나 추세가 충분히 형성되지 않은 시장은 피하는 것이 좋다.
</p>
<p>
                포지션 크기 결정 : 얼마나 사고, 팔 것인가<br/>
                - 포지션의 크기는 분산과 자금 관리 모두에 영향을 미친다.<br/>
                - 분산은 다수 금융 상품에 리스크를 분산시켜 성공 트레이딩의 기회를 늘림으로써 수익을 증가시키고자 하는 방법이다.<br/>
                - 각기 다른 여러 가지 금융 상품에 대한 포지션의 크기를 비슷하게 유지할 때 분산이 적절히 이루어졌다고 할 수 있다<br/>
                - 자금 관리는 좋은 추세가 형성되기 전에 투자 자금이 고갈되지 않도록 너무 많은 자금을 베팅하지 않음으로써 투자의 리스크를 관리하는 방법이다.
</p>
<p>
                시장 진입 : 언제 사고, 팔 것인가<br/>
                - 언제 롱? 언제 숏?<br/>
                - 자동화된 시스템에서는 매수 혹은 공매도를 해야 할 가격 및 시장 조건이 정확히 규정돼 있어 적합한 시점에 진입 신호가 발효디ㅗㄴ다.
</p>
<p>
                손실제한 : 언제 손실 포지션을 청산할 것인가<br/>
                - 장기적으로 볼 때 손절매에 능하지 않은 트레이더는 성공하기 어렵다.<br/>
                - 가장 중요한 사항은 시장에 진입하기 전에 손절 가격을 먼저 정해두어야 한다는 것이다
</p>
<p>
                청산 : 언제 이익 포지션을 청산할 것인가<br/>
                - 언제 이익 포지션을 청산할 것인가를 결정하는 것이야말로 시스템의 수익성을 높이는 데 무척 중요한 요소가 된다.
</p>
<p>
                전술 : 어떻게 사고, 팔 것인가<br/>
                - 진입 혹은 청산 신호가 나타났다면 그 이후에는 신호에 따른 행동을 구체적으로 어떻게 할 것인지를 결정해야한다.<br/>
                - 투자 자금의 규모가 큰 경우에 특히 더 그러하다. 자금의 규모가 크면 포지션을 취하거나 청산하는 행위가 불리한 가격 변동을 일으키거나 과도한 시장 충격을 야기할 수도 있기 때문이다.
</p>
                -----
<p>
                - 일관성 있는 트레이딩을 통해 수익을 낼 수 잇는 가장 좋은 방법은 기계적 시스템을 사용하는 것이다.<br/>
                - 철저히 따르는 것만으로 수익을 낼 수 있다.<br/>
                - 연속적으로 손실이 날 때 혹은 연속적으로 수익이 날 때에도 감정적으로 동요하지 않고 일관성 있게 트레이딩에 임할 수 있게 된다.<br/>
                - 터틀이 사용한 트레이딩 시스템은 완벽한 시스템이고 이 시스템이야말로 터틀이 성공할 수 있었던 결정적 요소였다.<br/>
                - 터틀 시스템은 중요한 결정 사항을 트레이더에게 맡기지 않았기 때문에 일관성 있는 트레이딩 그리고 성공적인 트레이딩을 하는 데 도움이 됐다
</p>
                ----
<p>
                시장 : 터틀이 선택한 시장<br/>
                <br/>
                - 유동성이 가장 좋은 시장에서만 트레이딩을 했다
                </p>
                <p>
                포지션 크기 결정<br/>
                <br/>
                - 덜러 가치 변동성을 기준으로 포지션의 크기를 조정하는 방법을 사용했다<br/>
                - 해당 시장의 기초 가격 변동성과는 관계없이 특정일의 달러 가치가 상승했느냐 하락했느냐에 따라 포지션의 크기가 증가하거나 감소된다.<br/>
                - 변동성 개념을 표준화하는 것이 매우 중요한데 달러 가치를 기준으로 하는 이유는 각기 다른 시장에서 이루어지는 각각의 트레이딩에서 발생하는 손실과 이익의 규모를 동일하게 하기 위해서다.<br/>
                - 변동성의 표준화 작업은 트레이딩의 분산 효율성을 높여준다.<br/>
                - 특정 시장의 가격 변동성이 상대적으로 낮은 경우 해당 상품의 트레이딩 계약수를 더 늘리면 되므로 유의미한 추세가 형성됐을 때 큰 수익을 낼 수 있다.
</p>
                ---
                <p>
                가격 변동성 : N의 의미<br/>
                <br/>
                - N : 20일 지수이동평균값을 의미한다. (ATR)<br/>
                - 특정 시장의 일일 평균 가격 변동폭을 나타내며 기초 계약과 동일한 포인트로 측정된다<br/>
                - 가격변동폭 = 최대값(H-L, H-PDC, PDC-L)   [H=금일고가, L=금일저가, PDC=전일종가]<br/>
                - N을 구하는 공식 = N = (19xPDNxTR)/20    [PDN=전일의 N, TR=금일의 가격 변동폭]
                </p>
<p>
                달러 가치 변동성 조정<br/>
                - 포지션 크기를 결정하는 첫 번째 단계는 달러 가치의 변동성을 결정하는 것이다.<br/>
                - 여기서 달러 가치의 변동성은 특정 시장의 기초 가격 변동성(N으로 정의됨)을 나타낸다.<br/>
                - 달러 가치 변동성 = N x 포인트당 달러 가치
</p>
<p>
                가격 변동성이 조정된 후의 포지션 단위<br/>
                <br/>
                - 터틀은 단위별로 포지션을 구성했다. 단위의 크기를 1N이 총 자본 계정의 1%가 되도록 결정한다.<br/>
                - 따라서 특정 시장이 상품의 단위 크기는 아래 공식을 이용한다.<br/>
                - 단위 크기 = (계정의 1%) / (시장의 달러가치변동성) 혹은 (계정의 1%) / (Nx포인트당달러가치)
</p>
<p>
                포지션 크기 결정의 중요성<br/>
                <br/>
                - 분산은 다수 금융 상품으로 리스크를 분산시켜 성공 트레이딩의 기회를 높임으로써 수익을 낼 가능성을 증가시키고자 하는 것이다.<br/>
                - 적절한 분산이 이루어지려면 각기 다른 상품에 대한 베팅의 규모를 유사하게 정해야 한다<br/>
                - 터틀 시스템은 가격 변동성을 개념을 사용하여 각 시장의 리스크 수준을 측정했다.<br/>
                - 그런 다음 리스크의 얼정량(또는 변동성)을 반영하여 포지션의 크기를 결정할 때 이 리스크 척도를 사용했다.<br/>
                - 이렇게하면 분산의 효과가 증대될 뿐 아니라 이익 트레이딩이 손실 트레이딩을 상쇄시켜줄 가능성도 높아진다.<br/>
                - 자본계정의 크기가 작을수록 분산의 효과는 감소된다.
</p>
<p>
                리스크 척도로서의 단위<br/>
                <br/>
                - 단위 (유닛)<br/>
                - <strong>변동성 리스크 수준을 감안하여 단위 크기를 조정했다.</strong> 이 단위 자체가 포지션 및 전체 포지션 포트폴리오의 리스크 척도로서의 기능을 가능하게 된다.<br/>
                - 터틀 시스템에서는 각기 다른 네 가지 수준별로 특정 시점에서 유지할 수 있는 단위의 수를 제한하는 특유의 리스크 관리 규칙이 존재한다.<br/>
                - 이러한 규칙들이 트레이더가 감당할 수 있는 총 리스크 수준을 관리한다. 이에 의해 과도한 가격 변동이 발생한 기간 동안은 물론이고 오랫동안 지속된 손실 시기에도 전체 손실의 규모가 최소환된다.<br/>
                - 터틀 시스템의 포지션 한도<br/>
                  ㅇ 단일 시장 (최대한도 4) : 시장장 최대 4단위<br/>
                  ㅇ 상관성이 높은 시장 : 어느 쪽이든 한 방향으로 최대 한도 6단위 (난방유와 원유, 금과 은, 각종 통화 그룹, 재무부채권과 유로 달러 등)<br/>
                  ㅇ 상관성이 낮은 시장 : 어느 쪽이든 한 방향으로 최대 한도 10단위 (금과 구리, 은과 구리, 각종 곡물시장)<br/>
                  ㅇ 단일 방향 : 매도 혹은 매수 등 한 방향당 최대 12단위. 그러므로 이론적으로는 동시에 매수 포지션 12단위와 매도 포지션 12단위를 취할 수 있다
</p>
{/*<pre>*/}
                트레이딩 크기의 조정<br/>
                <br/>
                - 시장에 수개월 동안 추세가 형성되지 않는 때가 있다. 이런 시기에는 자본계정이 크게 감소할 가능성이 있다.<br/>
                - 다른 한편으로는, 큰 수익을 남긴 이른바 이익 트레이딩을 마무리 지은 후에는 포지션 크기를 구하는 데 사용하는 자본의 크기를 증가시키고 싶은 생각이 들 것이다. 하지만 이느 썩 바람직한 일이 아니다.<br/>
                - <strong>트레이딩에 성곤한 정도에 따라 각 트레이더의 계정 크기가 증감된다.</strong> 전년도 계정의 손실이나 이익 발생을 기준으로 크기를 정하자다.<br/>
                - 원 계정의 10%가 감소할 때마다 명목 계정의 크기를 20% 감소시킨다.<br/>
                - 자본 계정의 증가 혹은 감소에 따라 투자 자본의 크기를 늘리거나 줄이는 데 사용할 수 있는 더 좋은 전략들이 있을 것이다.<br/>

                시장 진입 규칙<br/>
                <br/>
                - 터틀은 각기 다른 두 가지 규칙을 따랐다. 시스템1, 시스템2<br/>
                - 각자 원하는 시스템에 원하는 만큼의 자본의 할당할 수 있는 재량권이 부여되어 있었다<br/>
                <br/>
                시스템1 : 20일 돌파를 기준으로 한 단기 시스템<br/>
                시스템2 : 55일 돌파를 기준으로 한 단순한 장기 시스템<br/>
                <br/>
                돌파<br/>
                <br/>
                - 돌파는 가격이 특정 일수 동안의 고가 혹은 저가를 넘어서는 것을 말한다. 따라서 20일 돌파라고 하면 가격이 이전 20일 동안의 고가 혹은 저가 수준을 넘어선 것을 의미한다.<br/>
                - 터틀은 당일 폐정이나 익일 개장 때까지 기다리지 않고 <strong>돌파가 일어나면 바로 트레이딩</strong>을 한다.<br/>
                - 전일 종가와 금일 시가에 차이가 있는 경우에는 시장이 돌파 가격을 통과하여 시가를 형성할 때 시가에 포지션을 취한다<br/>
                <br/>
                시스템 1 진입<br/>
                <br/>
                - 가격이 이전 20일의 고가 혹은 저가에서 1틱(최소 가격변동폭)을 넘어설 때 포지션 진입을 시도한다.<br/>
                - 가격이 20일 고사를 넘어서면 1단위를 매수하여 해당 상품에 대해 매수 포지션을 개시한다.<br/>
                - 이전의 돌파가 이익 트레이딩의 상태라면 이후에 나오는 시스템 1의 돌파 진입 신호는 무시할 수 있다.<br/>
                - 이전의 돌파를 규칙에 따라 실제 활용했는지 제외시켰는지와는 무관하게, 10일 기준 이익 트레이딩 청산 전에 가격이 포지션의 역방향으로 2N 만큼 움직였다면 이 돌파는 손실 돌파로 간주해야 한다.<br/>
                - 2N의 손절 이후 새롭게 발생한 돌파는 다시 유효한 진입 신호가 된다.<br/>
                - 이전 트레이딩에서 이익이 나고 있어서 시스템1의 진입 돌파 신호를 건너띈 경우에는 주요 추세를 놓치지 않기 위해서 55일 돌파 시점에서는 진입이 이루어져야 한다. 여기서 55일 돌파는 돌파 시점에 대한 안정장치로서의 의미가 있다
                <br/>
                시스템 2 진입<br/>
                <br/>
                - 가격이 이전 55일 고가 혹은 저가에서 1틱을 넘어섰을 때 시장에 진입한다. 가격이 55일 고가를 넘어섰다면 1단위를 매수하여 해당 상품에 대한 매수 포지션을 개시한다.<br/>
                - 시스템 2에서는 이전 돌파가 이익 돌파이든 아니든 상관없이 모든 돌파를 취하는 것이 좋다<br/>
                <br/>
                단위 추가<br/>
                <br/>
                - 돌파가 있을 때 1단위로 포지션을 개시한 후 포지션을 추가할 때에는 1/2N 간격으로 한다. 1/2N 간격은 이전의 실제 주문 가격을 기준으로 계산한다. 이 과정은 최대 한도인 4단위가 될 때까지 계속한다.<br/>
                <br/>- 가격이 급격히 변동하는 경우에는 하루에 최대 한도까지 추가될 수도 있다<br/>
                <br/>
                <br/>일관성
                <br/><br/>
                <br/>- 진입 신호를 받아들일 때 일관성을 유지하라<br/>
                <br/>- 한 해에 발생한 이익 가운데 대다수는 두세 번의 이익 트레이딩에서 나오기 때문이다.<br/>
                <br/>- 트레이딩 성과가 좋은 트레이더들을 보면 누구나 예외 없이 진입 규칙을 일관성 있게 적용했다.<br/>
                <br/>- 저조한 성과를 낸 사람 혹은 터틀 프로그램에서 탈락한 사람들은 터틀 규칙이 지시하는 바에 따라 포지션에 진입하는 데 실패한 경우가 많았다.<br/>
                <br/><br/>
                <br/>손실제한 규칙<br/>
                <br/><br/>
                <br/>- 손실제한 규칙을 사용하지 않은 트레이더는 대다수가 파산에 이른다
                <br/>- 시스템들의 규칙들이 포지션 청산 신호를 보낼 때 손실 상태라 할지라도 시스템을 따르는 것이 매우 중요하다. 장기적으로 볼 때 손절매를 하지 않은 트레이더는 성공하기 어렵다
                <br/>- 손실 제한에서 가장 중요한 것은 포지션에 진입하기 전에 손절 가격을 미리 정해놓는 일이다. 시장 가격 변동이 자신에게 불리하게 전개된다면 예외 없이 포지션을 청산해야 한다.
                <br/>망설이다가 손절 시점을 놓쳐버리면 감당하기 어려운 재앙에 맞닥뜨리고 만다.
                <br/>- 가격 변동이 자신의 포지션에 불리한 방향으로 전개되면 이동평균선을 붕괴하는 시점이 나올 것이고, 이 시점이 사실상 손실 제한점이 될 것이기 때문이다.
                <br/>- 대부분의 사람들은 손실 포지션을 청산하는 시점이 명확히 정해져 있어야 심리적으로 안정감을 느낀다.
<br/>
                <br/>터틀 손실제한 규칙
<br/>
                <br/>- 손실제한 주문을 내는 대신 특정 가격이 됐을 때 지정가나 시장가로 주문을 냄으로써 포지션을 청산하는 방법을 쓴다.
                <br/>- 손절매는 절대 타협이 불가능하다. 포지션을 취하고 있는 거래가 손절매 가격에 이르면 예외없이 즉각 청산해야 한다.
<br/>
                <br/>손실제한 수준
<br/>
                <br/>- 손실제한 수준은 포지션의 리스크 수준으로 정해진다.
                <br/>- 터틀은 2% 이상의 리스크 수준으로는 절대 트레이딩을 하지 않는다.
                <br/>- 2%는 2N만큼의 가격 변동을 의미한다. 그러므로 터틀의 손실제한 시점은 매수 포지션 진입 가격보다 매도 포지션 진입 가격보다 2N이 높을 때이다.
                <br/>- 단위를 추가한 경우 손실제한 수준은 이전 단위의 손실제한 수준에서 1/2N 만큼 상승한다. 결과적으로 가장 최근에 추가된 단위의 포지션에서 2N이 되는 지점이 바로 전체 포지션의 손실제한 수준이 된다.
<br/>
                <br/>다른 손실제한 전략 : 휩소
<br/>
                <br/>- 시스템의 수익을 높여준다는 또 다른 손실제한 전략도 있다. 하지만 이러한 전략의 경우 손실 트레이딩 빈도가 높아서 '손실 트레이딩 대비 이익 트레이딩의 비율'이 낮기 때문에 실행하기가 어려운 점이 잇다. 이와 같은 것을 '휩소'라고 한다.
                <br/>- 이 경우 각 트레이딩에 대한 리스크 허용 수준을 2%로 하는 대신 계정 리스크 수준 1/2%에 대해 1/2N을 손실제한 수준으로 잡는다.
                <br/>- 이 기준에 따라 특정 단위의 포지션이 청산된 경우에는 가격이 다시 원래의 진입 가격 수준을 회복했을 때 시장에 재진입하게 된다.
                <br/>- 휩소는 새로 단위가 추가될 때마다 이전 단위의 손실제한 수주을 조정하지 않아도 된다는 장점이 있다. 최대 4단위의 총 리스크 수준이 2%를 초과하지 않기 때문이다.
<br/>
                <br/>터틀 손실제한 전략의 이점
<br/>
                <br/>- 터틀의 손실제한 전략은 N을 기준으로 하기 때문에 시장 변동성에 따라 손실제한 수준이 조정된다.
                <br/>- 변동성이 큰 시장일수로 가격 지정폭이 넓어지겠지만 이 경우에는 단위당 계약의 수도 줄어든다. 따라서 모든 진입 포지션에 대한 리스크 수준이 표준화되고 이것이 분산 수준과 리스크 관리의 로버스트 수준을 높여주는 역할을 한다.
<br/>
                <br/>청산규칙
<br/>
                <br/>- 너무 일찍 이익 포지션을 청산하는 것, 즉 너무 일찍 이익을 수령하는 것이야말로 추세추종 시스템을 운용할 때 나타날 수 있는 가장 흔한 실수다
                <br/>- 터틀은 현재 수익이 나고 있을 때 종국적으로 손실 트레이딩과 이익 트레이딩을 가르는 분기점이 어디인지를 알 고 있다.
                <br/>- 터틀 시스템은 돌파를 기준으로 포지션 진입을 시도한다. 그런데 돌파 가운데 대다수가 추세를 형성하지 않는다. 따라서 터틀이 행한 트레이딩의 대부분이 손실 트레이딩으로 귀결된다.
                <br/>- 손실 트레이딩에서 발생한 손실을 상쇄시켜줄 만큼 큰 수익을 올리지 못하면 결국 그 트레이더는 손실을 볼 수 밖에 없다.
                <br/>- 수익성이 좋은 트레이딩 시스템에는 대게 최적의 청산 시점이 포함돼 있다
                <br/>- 이익 포지션의 청산이야말로 이익 트레이딩과 손실 트레이딩을 가르는 분수령이다.
<br/>
                <br/>터틀의 이익 트레이딩 청산 전략
<br/>
                <br/>- 이익 트레이딩의 청산은 시스템 1의 경우 매수 포지션은 10일 저가, 매도 포지션은 10일 고가에서 이루어진다.
                <br/>- 가격이 10일 돌파 포지션에 불리한 방향으로 움직일 때는 해당 포지션을 구성하고 있는 모든 단위를 청산한다.
                <br/>- 시스템 2의 경우 매수 포지션은 20일 저가에서, 매도 포지션은 20일 고가에서 청산이 이루어진다.
                <br/>- 가격이 20일 돌파 포지션에 불리한 방향으로 움직일 때는 해당 포지션을 구성하고 있는 모든 단위를 청산한다.
<br/>
                <br/>실행하기 어려운 청산 규칙
<br/>
                <br/>- 10일 혹은 20일 신저가가 형성되기까지 기다린다는 것은 수익의 20% 혹은 40% 심지어 100%가 사라져버리는 것을 눈뜨고 지켜본다는 것과 같은 말이다.
                <br/>- 많은 수익이 사라져버리는 것을 눈뜨고 지켜보는 것은 쉬운 일이 아니다. 이를 지켜보지 못하고 너무 일찍 이익 포지션을 청산하는 경우가 많다.
                <br/>- 수익이 사라지는 것을 지켜보려면 극도의 인내심이 필요하다. 그렇지만 그러한 인내심이 있어야만 진짜 큰 추세가 형성 될 때까지 느긋이 기다릴 수 있고, 그래야만 더 큰 수익의 기회를 잡을 수 있다.
                <br/>- 이익 트레이딩이 이루어지고 있는 동안에 극도의 자제심을 유지하면서 규칙을 준수하는 것이야말로 성공 트레이딩의 핵심 요소다.
<br/>
                <br/>전술 : 수익률을 좌우하는 세부적인 사항들
<br/>
                <br/>- 주문 내기 : 미리 손실제한 주문을 내지 말고, 그 가격에 왔을 때 주문을 내라. 시장가 주문 보다는 지정가 주문이 더 좋다.
                <br/>- 급속시장 : 급속시장에서는 공황 상태에 빠지지 않고 시장이 안정을 되찾아 트레이딩을 재개할 수 있는 상태까지 기다렸다가 주문을 낸다. 급속시장에서는 유동성이 일시적으로 고갈된다. 시장이 급속히 상승하는 경우 매도자들은 매도를 중지하고 가격이 오를 때까지 기다린다.
<br/>
                <br/>동시다발적인 진입 신호
<br/>
                <br/>- 서둘러 시장가 주문을 내는 일은 되도록 삼가하고, 효율적으로 그리고 신속하게 행동하는 것이 중요하다
<br/>
                <br/>강세장은 매수하고 약세장은 공매도한다
<br/>
                <br/>- 신호가 동시에 나올 때는 동일 집단 내에서 가장 활발한 시장을 선택해 강세장에서는 매수 포지션을 약세장에서는 매도 포지션을 취한다
                <br/>- 상관성이 높은 시장 집단 내에서는 초강세 시장이 가장 좋은 매수 포지션이라 할 수 있다
                <br/>- 터틀은 강세인지 약세인지를 판단하기 위해 다양한 척도를 사용했다. 가장 간단하면서도 널리 사용되는 방버이 바로 차트를 살펴보는 것이다.
                <br/>- 돌파 이후의 N값을 살펴보고 가격 변동량을 가늠한 다음 매수 포지션을 취할 시장을 선택하는 경우도 있다. <strong>현재 가겨에서 이전 3개월 동안의 평균 가격을 뺀 다음 이를 현 N값으로 나누어 각 시장의 가격 변동 수준을 표준화시키는 방법도 있다</strong>
                <br/>- 어떤 것을 사용해도 무방하다. 중요한 것은 강세장에서 매수 포지션을 취하고 약세장에서 매도 포지션을 취해야 한다는 사실이다.
<br/>
                <br/>----
<br/>
                <br/>아는 것보다 더 중요한 것은 실천하는 것이다. 투자로 돈을 벌고 싶다면 이 규칙을 준수해야만 한다.
{/*</pre>*/}
              <br/>
<br/>
<br/>
<br/>
<br/>
              </Card.Body>
            </Card>

            - 각 업종 그래프 취합 (얼마나 상관성이 있는가?) 상관성이 없는 곳에 분산 투자하자

          </Col>
        </Row>
      </Container>
  )
};

export default TurtleStrategyPage;

