import _ from "lodash";
import {useEffect} from "react";

const GrowthNetIncome = ({stock, type = 'year'}) => {
  const finances = stock?.finances
  const filtered = finances?.filter((finance) => finance.type === type)
  const ordered = _.orderBy(filtered, ['year', 'month'], ['asc'])


  useEffect(() => {
    console.log('ordered', ordered)
  }, [ordered])

  const data = []


  for (let i = 0; i < ordered.length; i++) {
    if (i > 0) {
      const previous = ordered[i - 1]
      const current = ordered[i]
      const ratio = (current?.netIncome - previous?.netIncome) / Math.abs(previous?.netIncome) * 100
      const color = previous.netIncome < current.netIncome ? 'text-info' : 'text-danger'
      data.push({
        year: current.year,
        month: current.month,
        color: color,
        netIncome: current.netIncome,
        ratio: ratio?.toFixed(1),
        type: current.type,
      })
    } else {
      const current = ordered[0]
      data.push({
        year: current.year,
        month: current.month,
        color: '',
        netIncome: current.netIncome,
        ratio: undefined,
        type: current.type,
      })
    }
  }

  const averageGrowth = (Math.pow((ordered[ordered.length - 1]?.netIncome / ordered[0]?.netIncome), 1 / ordered?.length) - 1) * 100

  return <>
    <div className="d-flex">
      {data?.map((item) => (
          <div className="mx-1 text-center">
            <small>{item.year}/{item.month}</small><br/>
            <span className={`${item.color} fs-15`}>{item.netIncome}</span><br/>
            {item?.ratio && item.ratio !== 'NaN' && <small className={`${item.color}`}>({item.ratio}%)</small>}
          </div>
      ))}
      <div className="mx-1 text-center">
        <small>평균성장률</small><br/>
        {averageGrowth && <span className={`fs-15 ${averageGrowth > 10 ? 'text-info' : ''}`}>{averageGrowth?.toFixed(2)}%</span>}
      </div>
    </div>
  </>
}


export default GrowthNetIncome