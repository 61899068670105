import React from 'react';
import {Badge, Card, Table} from "react-bootstrap";
import PBR from "../value/PBR";
import PER from "../value/PER";
import InfoPopover from "../../partials/InfoPopover";
import {Info} from "../../partials/Info";
import PCR from "../value/PCR";
import PSR from "../value/PSR";
import NCAV from "../value/NCAV";
import POR from "../value/POR";
import PFCR from "../value/PFCR";
import EV_EBITDA from "../value/EV_EBITDA";

const ValueFactor = ({stock}) => {
  const company = stock.company
  const rank = stock?.rank

  const RankData = ({rankData}) => {
    return <>
     <span className={`${rankData?.rank <= 200 ? 'text-info' : ''}`}>
        {rankData?.rank && <span>{rankData?.rank}위, {rankData?.rankRatio?.toFixed(2)}%</span>}
      </span>
    </>
  }

  return (
      <>
        <Card>
          <Card.Header className='py-2'>
            <Card.Title><h6>밸류팩터</h6></Card.Title>
          </Card.Header>
          <Card.Body className="p-3">
            <Table className="table-sm table-finance">
              <tbody>
              <tr>
                <td>{(stock?.marketCap)?.toLocaleString()}</td>
                <td>{stock?.rank?.marketCap?.rank}위
                  <span className={stock?.rank?.marketCap?.rankRatio >= 80 ? 'text-info' : ''}> ({stock?.rank?.marketCap?.rankRatio?.toLocaleString()}%)</span>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <small className={stock?.stockType === 'large' ? 'text-info' : ''}>
                    {stock?.stockType === 'large' && <span>대형주</span>}
                    {stock?.stockType === 'medium' && <span>중형주</span>}
                    {stock?.stockType === 'small' && <span>소형주</span>}
                  </small>
                </td>
              </tr>
              <tr>
                <td>PBR(배) <InfoPopover info={Info.pbr}/></td>
                <td><PBR stock={stock}/></td>
              </tr>
              <tr>
                <td>PER(배) <InfoPopover info={Info.per}/></td>
                <td><PER stock={stock}/></td>
              </tr>
              <tr>
                <td>PCR <InfoPopover info={Info.pcr}/></td>
                <td><PCR stock={stock}/></td>
              </tr>
              <tr>
                <td>PSR <InfoPopover info={Info.psr}/></td>
                <td><PSR stock={stock}/></td>
              </tr>
              <tr>
                <td>NCAV <InfoPopover info={Info.ncav}/></td>
                <td><NCAV stock={stock}/></td>
              </tr>
              <tr>
                <td>POR <InfoPopover info={Info.por}/></td>
                <td><POR stock={stock}/></td>
              </tr>
              <tr>
                <td>PFCR <InfoPopover info={Info.pfcr}/></td>
                <td><PFCR stock={stock}/></td>
              </tr>
              <tr>
                <td>EV/EBITDA</td>
                <td><EV_EBITDA stock={stock}/></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>PER,PBR,PFCR,PSR<br/>순위</td>
                <td>
                  <RankData rankData={rank?.perPbrPfcrPsr}/>
                </td>
              </tr>
              <tr>
                <td>분기PER,PBR,분기PFCR<br/>분기PSR 순위</td>
                <td>
                  <RankData rankData={rank?.perFinancePbrPfcrFinancePsrFinance}/>
                </td>
              </tr>
              <tr>
                <td>저PBR,GP/A 순위</td>
                <td>
                  <RankData rankData={rank?.pbrGpA}/>
                </td>
              </tr>
              <tr>
                <td>NCAV비율 순위</td>
                <td>
                  <RankData rankData={rank?.ncavRatio}/>
                </td>
              </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
  )
}
export default ValueFactor;