import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useGetCompaniesQuery, useGetCompanyQuery, useGetSectorQuery, useGetSectorsQuery} from "../../../services/company";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import SectorChart from "./partials/SectorChart";

const SectorDetailPage = () => {
  let {sectorCode} = useParams()
  const [code, setCode] = useState(null);
  const {data: dataSectors} = useGetSectorsQuery()
  const {data: dataSector} = useGetSectorQuery({sectorCode: code}, {skip: code === null,})
  const [optionSectors, setOptionSectors] = useState([])
  const [selectedSector, setSelectedSector] = useState(null);

  useEffect(() => {
    if (!dataSectors) return
    const options = []
    for (const result of dataSectors?.results) {
      const option = {
        'value': result.id,
        'label': `${result.name} (${result.code})`,
        'code': result.code,
      }
      options.push(option)
      if (sectorCode === result.code) {
        setSelectedSector(option)
      }
    }
    setOptionSectors(options)
  }, [dataSectors]);

  useEffect(() => {
    setCode(sectorCode)
  }, [sectorCode]);

  useEffect(() => {
    console.log('selectedSector', selectedSector)
    if (selectedSector) {
      setCode(selectedSector.code)
      console.log('code', code)
    }
  }, [selectedSector]);

  const SectorSelector = () => {
    return <div style={{width: '250px'}} className="ps-2">
      {dataSectors?.results && <Select
          defaultValue={selectedSector}
          onChange={setSelectedSector}
          options={optionSectors}
      />}
    </div>
  }
  const Title = ({sector}) => {
    return <span className="text-black">{sector.name} <small className="text-muted">({sector.code})</small></span>
  }

  return <>
    <PageTitle activeMenu={dataSector && <Title sector={dataSector}/>}
               motherMenu="업종"
               pageContent={<SectorSelector/>}/>

    {dataSector && <SectorChart sector={dataSector}/>}
  </>
}

export default SectorDetailPage