const NCAV = ({stock}) => {
  const ncav = stock.ncav

  const Ratio = () => {
    if (stock?.ncavRatio) {
      return <small className={`${stock?.ncavRatio >= 100 ? 'text-info' : ''}`}>
        (시총대비 {stock?.ncavRatio.toFixed(2)}%)
      </small>
    }
    return <></>
  }

  return <>
    <span>{ncav?.toFixed(0)}</span>
    <Ratio/>
    {stock?.isNcav && <span className="badge badge-info">NCAV</span>}
  </>
}

export default NCAV
