const NetIncome = ({stock}) => {
  const f = stock?.finance

  const HigherThan100 = ({value}) => {
    const text = value?.toFixed(2)
    if (value > 50) {
      return <span className="text-info">{text}% </span>
    } else if (value < 0) {
      return <span className="text-danger">{text}% </span>
    }
    return <span>{text}% </span>
  }

  return <>
    {f && f?.type === 'quarter' && <>
      <p className="mb-0 fs-14">
        <HigherThan100 value={f?.netIncomeGrowthComparedToPreviousQuarter}/>
        <small>(전기, {stock?.rank?.nigPq?.rank}위, {stock?.rank?.nigPq?.rankRatio?.toFixed(2)}%) </small>
        <br/>
        <HigherThan100 value={f.netIncomeGrowthComparedToSamePeriodLastYear}/>
        <small>(작년동기, {stock?.rank?.nigLy?.rank}위, {stock?.nigLy?.rankRatio?.toFixed(2)}%)</small>
      </p>
    </>}
  </>
}

export default NetIncome

// {% if f.type == 'quarter' %}
//
//     <small>전기</small>, <small>작년동기</small><br>
//     {% include 'apps/companies/partials/content/higher_than_100.html' with value=f.net_income_growth_compared_to_previous_quarter %}
//     {% include 'apps/companies/partials/content/higher_than_100.html' with value=f.net_income_growth_compared_to_same_period_last_year %}
//
//
//     <br>
//     <small>
//         ({{ r.nig_pq_rank }}위, {{ r.nig_pq_rank_ratio|floatformat:2 }}%)
//         ({{ r.nig_ly_rank }}위, {{ r.nig_ly_rank_ratio|floatformat:2 }}%)
//     </small>
// {% endif %}