import {Outlet, useRoutes} from "react-router-dom";
import Home from "./jsx/components/Dashboard/Home";
import CompanyListPage from "./jsx/components/Company/CompanyListPage";
import CompanyDetailPage from "./jsx/components/Company/CompanyDetailPage";
import Error404 from "./jsx/pages/Error404";
import React from "react";
import StrategyPage from "./jsx/components/Guide/StrategyPage";
import IndexList from "./jsx/components/Index/IndexList";
import SectorListPage from "./jsx/components/Sector/SectorListPage";
import ValueStrategyPage from "./jsx/components/Guide/ValueStrategyPage";
import TurtleStrategyPage from "./jsx/components/Guide/TurtleStrategyPage";
import SectorDetailPage from "./jsx/components/Sector/SectorDetailPage";
import TurtleListPage from "./jsx/components/Starategy/TurtleListPage";

const Router = () => {
  let element = useRoutes([
    // Route에서 사용하는 props의 요소들과 동일
    {path: "", element: <Home/>, index: true},
    {path: "dashboard", element: <Home/>},
    {path: "index", element: <IndexList/>},
    {
      path: "companies",
      element: <Outlet/>,
      children: [
        {path: "", element: <CompanyListPage/>},
        {path: "detail", element: <CompanyDetailPage/>},
        {path: ":companyCode", element: <CompanyDetailPage/>},
      ]
    },
    {
      path: "sectors",
      element: <Outlet/>,
      children: [
        {path: "", element: <SectorListPage/>},
        {path: ":sectorCode", element: <SectorDetailPage/>},
      ]
    },
    {
      path: "strategy",
      element: <Outlet/>,
      children: [
        {path: "", element: <StrategyPage/>},
        {path: "value", element: <ValueStrategyPage/>},
        {path: "turtle", element: <TurtleListPage/>},
      ]
    },
    {
      path: "guide",
      element: <Outlet/>,
      children: [
        {
          path: "strategy",
          element: <Outlet/>,
          children: [
            {path: "", element: <StrategyPage/>},
            {path: "value", element: <ValueStrategyPage/>},
            {path: "turtle", element: <TurtleStrategyPage/>},
          ]
        },
      ]
    },
    // NotFound 페이지는 다음과 같이 구현할 수 있음
    {path: "*", element: <Error404/>}
  ]);
  return element
}

export default Router