import PageTitle from "../../layouts/PageTitle";
import React from "react";
import TurtleList from "./partials/TurtleList";

const TurtleListPage = () => {
  return <>
    <PageTitle activeMenu="터틀 전략" motherMenu="전략"/>
    <TurtleList/>
  </>
}

export default TurtleListPage