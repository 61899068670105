import {Card, Table} from "react-bootstrap";
import React from "react";
import InfoPopover from "../../partials/InfoPopover";
import {Info} from "../../partials/Info";
import PBR from "../value/PBR";
import GP_A from "../quality/GP_A";
import ROE from "../quality/ROE";
import ROA from "../quality/ROA";
import FScore from "../quality/FScore";
import DebtRatio from "../quality/DebtRatio";
import CurrentRatio from "../quality/CurrentRatio";
import IntrinsicValue from "../quality/IntrinsicValue";
import BPS from "../quality/BPS";
import ReserveRatio from "../quality/ReserveRatio";

const QualityFactor = ({stock}) => {
  const company = stock

  return <>
    <Card>
      <Card.Header className="py-2">
        <Card.Title><h6>퀄리티팩터</h6></Card.Title>
      </Card.Header>
      <Card.Body className="p-3">
        <Table className="table-sm table-finance">
          <tbody>
          <tr>
            <td>GP/A <InfoPopover info={Info.gpA}/></td>
            <td><GP_A stock={stock}/></td>
          </tr>
          <tr>
            <td>ROE <InfoPopover info={Info.roe}/></td>
            <td><ROE stock={stock}/></td>
          </tr>
          <tr>
            <td>ROA <InfoPopover info={Info.roa}/></td>
            <td><ROA stock={stock}/></td>
          </tr>
          <tr>
            <td>F-Score <InfoPopover info={Info.fScore}/></td>
            <td><FScore stock={stock}/></td>
          </tr>
          <tr>
            <td>부채비율(%) <InfoPopover info={Info.debtRatio}/></td>
            <td><DebtRatio stock={stock}/></td>
          </tr>
          <tr>
            <td>유동비율 <InfoPopover info={Info.currentRatio}/></td>
            <td><CurrentRatio stock={stock}/></td>
          </tr>
          <tr>
            <td>내재가치 <InfoPopover info={Info.intrinsicValue}/></td>
            <td><IntrinsicValue stock={stock}/></td>
          </tr>
          <tr>
            <td>주당순자산 <small>(청산가치)</small> <InfoPopover info={Info.bps}/></td>
            <td><BPS stock={stock}/></td>
          </tr>
          <tr>
            <td>유보율 <InfoPopover info={Info.reserveRatio}/></td>
            <td><ReserveRatio stock={stock}/></td>
          </tr>
          <tr>
            <td colSpan="2" className="small">
              <small>영업이익/자본총계, 자산성장률, 차입금비율 증가율, 영업이익/차입금 증가율, 이익 변동성, 주가 변동성, 베타</small>
            </td>
          </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </>
}

export default QualityFactor