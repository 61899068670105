import InfoPopover from "../../partials/InfoPopover";
import {Info} from "../../partials/Info";

const PBR = ({stock}) => {
  const pbr = stock?.pbr

  const Data = () => {
    if (pbr > 0.2 && pbr < 0.6) {
      return <span className="text-info">{pbr} </span>
    } else if (pbr > 4 || pbr < 0.2) {
      return <span className="text-danger">{pbr} </span>
    } else {
      return <span>{pbr} </span>
    }
  }

  const Rank = () => {
    if (stock?.rank?.pbr?.rank) {
      return <small>
        ({stock?.rank?.pbr?.rank}위, <span className={stock?.rank?.pbr?.rankRatio <= 20 ? 'text-info' : ''}>{stock?.rank?.pbr?.rankRatio?.toFixed(2)}</span>)
      </small>
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>
  </>
}

export default PBR