const GP_A = ({stock}) => {
  const f = stock.finance
  const gpARank = stock?.rank?.gpA

  const Data = () => {
    const text = gpARank?.value?.toFixed(2)
    if (gpARank?.rankRatio < 50) {
      return <span className="text-info">{text}</span>
    }
    return <span>{text}</span>
  }

  const Rank = () => {
    if (gpARank?.rank) {
      return <small> ({gpARank?.rank}위 / {gpARank?.rankRatio?.toFixed(2)}%)</small>
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>
  </>
}

export default GP_A