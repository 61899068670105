import React from "react";
import {Bar, Line} from "react-chartjs-2";
import _ from 'lodash'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PrimaryColor, {Colors} from "../../../../utils/Color";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const SupplyChart = ({stocks}) => {
  const ordered = _.orderBy(stocks, ['date'], ['asc']).slice(-120)
  const data = {
    defaultFontFamily: "Poppins",
    labels: ordered.map(({date}) => date),
    datasets: [
      // {
      //   label: "기관+외국인",
      //   data: ordered.map(({netBuyPriceOrganizationAndForeigner}) => netBuyPriceOrganizationAndForeigner),
      //   borderColor: Colors.organizationAndForeigner,
      //   borderWidth: 1,
      //   backgroundColor: Colors.organizationAndForeigner,
      //   pointRadius: 0,
      //   fill: true,
      //   stack: 'Stack 0',
      // },
      {
        label: "기관",
        data: ordered.map(({netBuyPriceOrganization}) => netBuyPriceOrganization),
        borderColor: Colors.organization,
        borderWidth: 2,
        backgroundColor: Colors.organization,
        pointRadius: 0,
        fill: true,
        // stack: 'Stack 1',
      },
      {
        label: "외국인",
        data: ordered.map(({netBuyPriceForeigner}) => netBuyPriceForeigner),
        borderColor: Colors.foreigner,
        borderWidth: 2,
        backgroundColor: Colors.foreigner,
        pointRadius: 0,
        fill: true,
        // stack: 'Stack 1',
      },
      // {
      //   label: "224일 이동평균선",
      //   data: ordered.map(({sma224}) => sma224),
      //   borderColor: Colors.gray,
      //   borderWidth: 2,
      //   backgroundColor: Colors.gray,
      //   pointRadius: 0,
      // },
    ],
  };

  const options = {
    responsive: true,
    legend: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
    // scales: {
    //   yAxes: [
    //     {
    //       type: 'linear',
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    //   xAxes: [
    //     {
    //       type: 'linear',
    //       barPercentage: 0.5,
    //     },
    //   ],
    // },
  };

  return (
      <>
        <Bar options={options} data={data} height={100}/>
      </>
  );
}

export default SupplyChart;
