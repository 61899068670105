import React, {Fragment, useState} from "react";
import {Bar, Line} from "react-chartjs-2";
import _ from 'lodash'
import {Button, Modal, Table} from "react-bootstrap";
import CompanyChart from "../partials/CompanyChart";


const TurtleTable = ({stocks}) => {
  const ordered = _.orderBy(stocks, ['date'], ['desc'])

  const [company, setCompany] = useState(null)
  const [detailModal, setDetailModal] = useState(false)

  const onStockClicked = (stock) => {
    console.log('onStockClicked', stock)
    setCompany(stock.company)
    setDetailModal(true)
  }

  return (
      <>
        <Table className="table-bordered table-sm text-center w-100">
          <thead>
          <tr>
            <th>종목</th>
            <th>날짜</th>
            <th>진입 시점</th>
            <th>진입 가격</th>
            <th>손절가격</th>
            <th>수익률</th>
            <th>224일선위?</th>
          </tr>
          </thead>
          <tbody>
          {ordered?.map((stock, index) => (
              <Fragment key={`buy-${stock.id}-${index}`}>
                {stock.turtleLongSignalBuy && <tr key={`buy-${stock.id}-${index}`}>
                  <td onClick={() => onStockClicked(stock)}>{stock?.company?.name} {stock?.sector?.name}</td>
                  <td>{stock?.date}</td>
                  <td onClick={() => onStockClicked(stock)} className="text-danger">
                    매수 {stock?.turtleLongSignalRealBuy && <span>(Real)</span>}
                  </td>
                  <td>{stock?.turtleLongEntryPrice?.toLocaleString()}</td>
                  <td>{stock?.turtleLongStopLossPrice?.toLocaleString()}</td>
                  <td></td>
                  <td>
                    {stock?.sma224 && stock?.sma224 < stock?.close && <span> UP224</span>}
                    {stock?.sma120 && stock?.sma120 <= stock?.close && <span> UP120</span>}
                  </td>
                </tr>}
                {stock.turtleLongSignalSell && <tr key={`sell-${stock.id}-${index}`}>
                  <td onClick={() => onStockClicked(stock)}>{stock?.company?.name} {stock?.sector?.name}</td>
                  <td>{stock?.date}</td>
                  <td onClick={() => onStockClicked(stock)} className="text-success">매도</td>
                  <td>{stock?.turtleLongEntryPrice?.toLocaleString()}</td>
                  <td>{stock?.turtleLongStopLossPrice?.toLocaleString()}</td>
                  <td>{stock?.turtleRor}%</td>
                  <td>
                    {stock?.sma224 < stocks?.close && <span>224일선위</span>}
                    {stock?.sma120 < stocks?.close && <span>120일선위</span>}
                  </td>
                </tr>}
              </Fragment>
          ))}
          </tbody>
        </Table>


        <Modal className="fade" show={detailModal} size="xxl">
          <Modal.Header>
            <Modal.Title>{company?.name}</Modal.Title>
            <Button
                variant=""
                className="btn-close"
                onClick={() => setDetailModal(false)}
            >
            </Button>
          </Modal.Header>
          <Modal.Body>
            <CompanyChart company={company}/>
          </Modal.Body>
          <Modal.Footer>
            <Button
                onClick={() => setDetailModal(false)}
                variant="danger light"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
  );
}

export default TurtleTable;
