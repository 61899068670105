import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";

const PageTitle = ({motherMenu, activeMenu, pageContent}) => {
  let path = window.location.pathname.split("/");
  const navigate = useNavigate();

  return (
      <Row className="page-titles mx-0 px-3">
        <Col>
          <ol className="breadcrumb py-2 pb-0">
            <li className="breadcrumb-item active"><Link to={`/${path[path.length - 2]}`}>{motherMenu}</Link></li>
            <li className="breadcrumb-item">{activeMenu}</li>
            {/*<li className="breadcrumb-item"><Link to={`/${path[path.length - 1]}`}>{activeMenu}</Link></li>*/}
          </ol>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant={'light'} className="btn-xs me-2" onClick={() => navigate(-1)}>뒤로가기</Button>
          {pageContent && pageContent}
        </Col>
        { /* <div className="col-sm-6 p-md-0">
			<div className="welcome-text">
			  <h4>Hi, welcome back!</h4>
			  <p className="mb-0">
				{pageContent ? pageContent : "Your business dashboard template"}
			  </p>
			</div>
		  </div>
		  <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
			<ol className="breadcrumb">
			  <li className="breadcrumb-item">
				<Link to={`/${path[path.length - 1]}`}>{motherMenu}</Link>
			  </li>
			  <li className="breadcrumb-item active c">
				<Link to={`/${path[path.length - 1]}`}>{activeMenu}</Link>
			  </li>
			</ol>
		  </div> */}

      </Row>
  );
};

export default PageTitle;
