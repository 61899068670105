import {Card, Table} from "react-bootstrap";
import React from "react";
import InfoPopover from "../../partials/InfoPopover";
import {Info} from "../../partials/Info";

import OperatingProfit from "../momentum/OperatingProfit";
import NetIncome from "../momentum/NetIncome";
import GrowthNetIncome from "../momentum/GrowthNetIncome";
import GrowthROE from "../momentum/GrowthROE";
import GrowthPER from "../momentum/GrowthPER";


const MomentumRatio = ({stock}) => {
  const company = stock.company
  const f = company.finance

  return <>
    <Card>
      <Card.Header className="py-2">
        <Card.Title><h6>모멘텀팩터</h6></Card.Title>
      </Card.Header>
      <Card.Body className="p-3">
        <Table className="table-sm table-finance">
          <tbody>
          <tr>
            <td>영업이익</td>
            <td>
              <OperatingProfit stock={stock}/>
            </td>
            <td rowSpan={2} className={`text-center ${stock?.rank?.opgNig?.rank <= 100 ? 'text-info' : ''}`}>
              <small>성장률 순위</small><br/>
              {stock?.rank?.opgNig?.rank}위, {stock?.rank?.opgNig?.rankRatio?.toFixed(2)}%
            </td>
          </tr>
          <tr>
            <td>순이익</td>
            <td><NetIncome stock={stock}/></td>
          </tr>
          <tr>
            <td>당기순이익(연간)</td>
            <td colSpan={2}>
              <GrowthNetIncome stock={stock} type={'year'}/>
            </td>
          </tr>
          <tr>
            <td>당기순이익(분기)</td>
            <td colSpan={2}>
              <GrowthNetIncome stock={stock} type={'quarter'}/>
            </td>
          </tr>
          <tr>
            <td>ROE</td>
            <td colSpan={2}>
              <GrowthROE stock={stock}/>
            </td>
          </tr>
          <tr>
            <td>PER</td>
            <td colSpan={2}>
              <GrowthPER stock={stock}/>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <small>주가 모멘텀 1개월 역추세, 주가 모멘텀 3~12개월 추세</small>
            </td>
          </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </>
}

export default MomentumRatio