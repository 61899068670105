import React from 'react';
import {Badge, Card, Col, Row} from "react-bootstrap";
import ValueFactor from "./ValueFactor";
import QualityFactor from "./QualityFactor";
import MomentumFactor from "./MomentumFactor";

const CompanyItem = ({stock}) => {
  const company = stock.company
  const f = company.finance

  return (
      <>
        <Card key={company.code}>
          <Card.Header>
            <Card.Title>
              {company.name} <small className="text-muted">{company.code}</small>
              <small>{f && <small className="text-muted"> ({f.year}/{f.month})</small>}</small>
            </Card.Title>
            <a href={`https://finance.naver.com/item/main.nhn?code=${company.code}`} target="_blank" className="float-right">
              <i className="icon-link ms-2"/>
            </a>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <ValueFactor stock={stock}/>
              </Col>
              <Col lg={3}>
                <QualityFactor stock={stock}/>

                <Card>
                  <Card.Body className="p-3">
                    {company?.sectors && <span>섹터 : </span>}
                    {company?.sectors?.map((sector) => (
                        <span key={sector.id}>{sector.name} </span>
                    ))}
                    {company?.sectors && <br/>}

                    {company?.themes && <span>테마 : </span>}
                    {company?.themes?.map((theme) => (
                        <span key={theme.id}>{theme.name} </span>
                    ))}

                    {company.status === 'halt' && <Badge variant={"danger"}>거래정지종목</Badge>}
                    {company.status === 'management' && <Badge variant={"danger"}>관리종목</Badge>}
                    {company.status === 'alert' && <Badge variant={"danger"}>시장경보종목</Badge>}
                  </Card.Body>
                </Card>

              </Col>
              <Col lg={6}>
                <MomentumFactor stock={stock}/>
              </Col>
            </Row>

          </Card.Body>
        </Card>
      </>
  )
}
export default CompanyItem;