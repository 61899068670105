import React, {useEffect, useState} from 'react';
import {useGetDetailCompaniesQuery} from "../../../../services/company";
import CompanyStockItem from "./CompanyItem";
import {useGetCompanyStocksQuery} from "../../../../services/stock";

const CompanyList = ({sector, theme, ordering, filter}) => {
  // const {data: dataCompanies, isLoading} = useGetDetailCompaniesQuery({
  const {data: dataStocks, isLoading} = useGetCompanyStocksQuery({
    date: '2022-11-04',
    sector: sector,
    company: undefined,
    theme: theme,
    code: undefined,
    stockType: undefined,
    search: undefined,
    page: 1,
    pageSize: 30,
    ordering: ordering ?? '-market_cap',
    filter: filter ?? '',
  })

  const [stocks, setStocks] = useState([])

  useEffect(() => {
    dataStocks && setStocks(dataStocks?.results)
  }, [dataStocks])

  return (
      <>
        {isLoading && <span>로딩중</span>}
        {stocks?.map((stock) => (
            <CompanyStockItem stock={stock}/>
        ))}
      </>
  )
}
export default CompanyList;