import {OverlayTrigger, Popover} from "react-bootstrap";
import React from "react";

const InfoPopover = ({info}) => {
  return <>
    <div className="bootstrap-popover-wrapper d-inline">
      <div className="bootstrap-popover d-inline-block mb-0">
        <OverlayTrigger
            trigger="hover"
            key={'test'}
            placement={'top'}
            responsive={true}
            overlay={
              <Popover className="mb-2">
                <Popover.Title as="h3">{info.title}</Popover.Title>
                <Popover.Content>
                  {info.content}
                </Popover.Content>
              </Popover>
            }
        >
          <i className="icon-info"></i>
        </OverlayTrigger>
      </div>
    </div>
  </>
}

export default InfoPopover