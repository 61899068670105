const PFCR = ({stock}) => {
  const pfcr = stock?.pfcr
  const pfcrRank = stock?.rank?.pfcr
  const pfcrFinanceRank = stock?.rank?.pfcrFinance

  const Data = () => {
    const text = pfcr?.toFixed(2)
    return <>{text}</>
  }

  const Rank = () => {
    if (pfcrRank?.rank) {
      return <small>({pfcrRank.rank}위, {pfcrRank.rankRatio.toFixed(2)}%)</small>
    }
    return <></>
  }

  const Finance = () => {
    if (pfcrFinanceRank?.value) {
      return <span>(분기 {pfcrFinanceRank?.value?.toFixed(2)}
        <small> ({pfcrFinanceRank?.rank}위, <span class={`${pfcrFinanceRank.rankRatio <= 20 ? 'text-info' : ''}`}>{pfcrFinanceRank.rankRatio?.toFixed(2)}%</span>)</small>)
      </span>
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>
    <Finance/>
  </>
}

export default PFCR
