import IndexItem from "./partials/IndexItem";
import {Card, Col, Row} from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import React from "react";

const IndexList = () => {
  return <>
    <PageTitle
        activeMenu="KOSPI & KOSDAQ"
        motherMenu="지수"
    />
    <Row>
      <Col>
        <IndexItem indexType={'KOSPI'}/>
      </Col>
      <Col>
        <IndexItem indexType={'KOSDAQ'}/>
      </Col>
    </Row>
  </>
}

export default IndexList