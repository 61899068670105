const IntrinsicValue = ({stock}) => {
  const f = stock?.finance
  const intrinsicValue = f?.intrinsicValue

  return <>
    <span>{intrinsicValue?.toFixed(0)}</span>
  </>
}


export default IntrinsicValue



