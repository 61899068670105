export const Info = {
  pbr: {
    title: 'PBR',
    content: `
    시가총액/당기순이익 또는 주가/주당순이익<br>
    <br>
    기업의 순자산 1원을 증권시장의 얼마의 가격으로 평가하는가를 나타내는 수치다. 투자자들은 이를 척도로 서로 다른 주식의 상대적 가격을 파악할 수 있다.<br>
    기업이 얼마나 저평가되었는지 단순히 평가하고 싶을 때 PER과 가장 많이 쓰이는 지표이다. 기본적으로 PBR이 높으면(낮으면)
    가진 자본은 적지만(많지만) 미래 수익 전망이 높아서(낮아서) 시장의 기대가 높은(낮은) 것이라고 해석하면 된다.
    <br>
    6장. 소형주 + 저PBR 전략<br>
    <strong>한국에서도 소형주 수익이 대형주보다 훨씬 높고, 저PBR 주식의 수익이 주가지수나 고PBR 주식보다 훨씬 높다.</strong>
    이 책에 나오는 전략 대부분은 소형주의 알파가 더 높다.
    소형주 내에서 PBR이 0.6이하인 주식만 사고, PBR 4가 넘는 주식을 절대로 피하기만 해도 당신은 부자가 될 것이다.<br>
    PBR 0.2인 종목은 위험할 수 있으니 빼자.<br>
    <br>
    가치주(저PBR) - 평범주 - 성장주(고PBR)
    `
  },
  per: {
    title: 'PER (Price Earning Ratio, 주가수익배수)',
    content: `
      PER = 시가총액/당기순이익 또는 주가/주당순이익<br>
      <br>
      한 기업이 얻은 순이익 1원을 증권시장이 얼마의 가격으로 평가하고 있는가를 나타내는 수치다.<br>
      투자자들은 이를 척도로 서로 다른 주식의 상대적 가격을 파악할 수 있다.<br>
      기본적으로 PER이 높으면 현 이익은 적지만 미래 수익 전망이 높아서 시장의 기대가 높은 것이라고 해석하면 된다.<br>
      반대로 PER이 낮으면 이익은 많지만 수익 전망이 낮아서 시장의 기대가 낮은 것이다.
      <hr>
      시가 총액을 1년 순이익으로 나눈 것, 회사가 몇 년을 벌면 시가 총액만큼의 재산을 늘릴 수 있는지 나타낸다.
      <br>
      PER이 지나치게 작다는 기준은 3으로 잡으면 될 것 같다.<br>
      장기성장성이 높으면 PER이 높고, 장기성장성이 낮으면 PER이 낮다.<br>
      <br>
      <span class="text-primary">
          조선, 해운, 반도체, 자동차, 철강, 유화 등 경기민감주는 PER이 매우 높을 때 매수하고,
          PER이 가장 낮을 때 매도하는 전략을 추천한다. 상식과는 정반대다.</span>
    `
  },
  pcr: {
    title: 'PCR (Price Cash Flow Ratio, 주가현금흐름배수)',
    content: `
    PCR = 시가총액 / 영업현금흐름 또는 주가 / 주당영업현금흐름<br>
    특정 기업은이 얻은 영업현금흐름 1원을 증권시장이 얼마의 가격으로 평가하고 있는지 나타내는 수치다.
    투자자는 이를 척도로 서로 다른 주식의 상대적 가격을 파악할 수 있다. PER과 매우 비슷한 지표인데,
    순이익은 회계 조작 가능성이 높다. 투자자들이 기업의 순이익을 주로 보기 때문에 기업들은 순이익을 높이려고 애를 쓴다.
    따라서 이보다는 조작이 덜 되는 영업이익이나 영업현금흐름을 보는 투자자가 많다.<br>
    PCR도 PER과 마찬가지로 낮을수록 저평가된 기업이다. 기본적으로 PCR이 높으면(낮으면) 당장 벌어들이는 현금은 적지만(많지만),
    미래 수익 전망이 높아서(낮아서) 시장의 기대가 높다고(낮다고) 해석하면 된다.
    `
  },
  psr: {
    title: 'PSR (Price Selling Ratio, 주가매출액배수)',
    content: `
    PSR = 시가총액/매출액<br>
    PER과 비슷하게 기업의 저평가 정도를 측정하는 지표다. PER을 따지는 투자자들은 기업의 순이익을 중요시하므로
    기업들이 순이익을 조작하기 위해 많이 애를 쓴다. 그러나 매출액은 조작하기 어렵기 때문에 PSR을 선호하는 투자자도 많다.<br>
    PSR도 PER과 같이 낮을수록 저평가된 기업이다. 기본적으로 psr이 높으면(낮으면) 현 매출은 적지만(많지만)
    미래 수익 전망이 높아(낮아서) 시장의 기대가 높다고(낮다고) 해석하면 된다.<br>
    <br>
    피셔. "PSR은 주식의 인기도를 완벽하게 측정할 수 있는 지표"<br>
    PSR이 낮은 주식은 정말 인기 없는 주식이고, PSR이 높으면 인기가 매우 높다는 것이다.<br>
    PSR이 가장 낮은 1% 그룹은 빼자. 위험한 경우일 수 있다.<br>
    현명한 투자자는 PSR이 0.35 이하인 기업에 투자한다.
    PSR이 3.2를 넘어가는 주식은 정말 대단한 매리트가 없으면 피하고, PSR이 7.7 이 넘으면 도망가자.
    `
  },
  ncav: {
    title: 'NCAV (Net Current Asset Value, 순유동자산)',
    content:`
    순유동자산 NCAV = 유동자산 - 총부채
      <br>
      비유동자산의 가치를 0으로 평가해 기업의 청산가치를 매우 보수적으로 측정하는 지표이다.
    `
  },
  por: {
    title: 'POR',
    content:`
     POR = 시가총액/영업이익
      <br>
      영업이익은 순이익의 품질을 평가하는 좋은 보조 지표가 된다.
      POR이 낮은 그룹은 수익률이 좋고, POR이 높은 그룹들의 수익률이 낮았다.
      전 종목을 대상으로 한 실험에서 최저 POR 그룹의 평균 POR은 2.4 밖에 안되고,
      최고 POR 그룹의 평균값은 73이다. 현재처럼 벌면 73년을 더 벌어야 시가총액만큼 영업 이익을 내게 된다는 말이다.<br>
      전반적으로 
    `
  },
  pfcr: {
    title: 'PFCR',
    content:`
    기업 가치를 평가할 때 잉여현금흐름 개념이 등장한다. 기업은 영업으로 현금을 버는데 그중 일부를 설비에 재투자한 후 남는 돈이,
    주주가 자유롭게 배당할지 기업에 남겨둘지 결정할 수 있는 현금이라 해서 '잉여현금흐름'으로 불린다.<br>
    잉여현금흐름은 가치투자의 원톱인 워런 버핏이 매우 중요하게 지표이다.
    <br>
    기업의 가치를 평가하는 방법은 여러 가지인데 이론적으로는 현금흐름 할인법(Discounted Cash Flow, DCF)이 가장 완벽하다.
    기업이 벌어들일 미래 현금흐름의 현재 가치를 계산하는 것으로서, 여기에 쓰이는 현금흐름이 학계는 잉여현금흐름,
    버핏은 주주 수익(owner earning)이라고 부르는 것이다.<br>
    <br>
    이 현금흐름은 영업활동현금흐름에서 설비투자비용(CAPEX)을 빼서 계산한다.<br>

    기업은 영업을 통해 현금을 벌어들이이는데 그중 일부는 생산 시설 등 설비를 유지하고 새것으로 업그레이드하는 데 사용해야
    경쟁력 있는 제품을 생산할 수 있기 때문이다. 따라서 영업활동현금흐름에서 설비 투자를 제한 잉여현금흐름만 주주들이 자유롭게 활용할 수 있는 진정한 현금흐름으로 간주한다.
    <br>
    그렇다면 시가총액이 잉여현금흐름 대비 상대적으로 작으면 주식이 저평가, 높으면 고평가 상태라고 가정할 수 있겠다.<br>
    <br>
    잉여현금흐름 = 영업활동현금흐름 - CAPEX<br>
    <br>
    PFCR = 시가총액/잉여현금흐름
    `
  },
  gpA: {
    title: 'GP/A',
    content: `
    GP = 매출액 - 매출원가 = 매출총이익, A는 총자산<br>
    GP/A = 매출총이익 / 총자산<br>
    <br>
    신 마법공식 : 자본수익률이 높은 종목이 시장에 비해 싸게 거래될 때 미입하는 전략.<br>
    GP/A는 매촐총이익(Gross Profit)을 총자산 (Asset)으로 나눈 것 : 기업의 수익성을 측정하는데 유용
    `
  },
  roe: {
    title: 'ROE(Return On Equity, 자기자본이익률)',
    content: `
    ROE = 순이익/자기자본
    투자입한 자기자존이 얼마만큼의 순이익을 냈는를 나타내는 지표다. <br>
    우량주를 측정하는데 이보다 더 좋은 지표가 있을까? 워런 버핏도 매우 중요하게 여기는 지표다.<br>
    <br>
    하지만 이 수치만 보고 판단하는 것은 패가망신의 지름길이다.
    <br>
    자기자본이익률(ROE)은 순자산에 비해 얼마나 이익을 내는지는 재는 지표다.<br>
    자본 총계에 대한 이익 창출 능력을 나타낸다.<br>
    다른 지표들과 같은 경향성은 보이지는 않는다. 다만 지나치게 낮으면 수익률이 눈에 띄게 낮다.<br>
    평균ROE가 마이너스, 즉 평균 순익이 적자인 회사들이다. ROE가 높은 회사는 운영을 효율적으로 잘하는 회사다.
    <br><br>

    5~10년에 걸쳐 ROE흐름을 파악해보자. <strong>일반적으로 ROE가 꾸준히 10% 넘으면 '괜찮은' 기업이다.</strong> (좋은주식, 나쁜주식)
    `
  },
  roa: {
    title: 'ROA (Return on Assets, 총자산순이익률)',
    content: `
     ROA = 순이익/총자산<br>
      기업의 자산인 자본과 부채를 활용해 어느 정도 이익을 창출했는지 알려주는 대표적인 수익성 지표이다.
      기업이 자산을 얼마나 효율적으로 운용했는지 나타낸다.
      <br>
      이것만 보고 미래 수익률을 예측하기에는 훌륭한 지표가 아니다.
    `
  },
  fScore: {
    title: '신F-스코어',
    content: `
    (1) PBR 최저 20% 기업에만 투자<br>
    (2) 아래의 3개 요소를 분석해서, 맞으면 1점, 틀리면 0점을 배분<br>
    - 수익이 0 이상<br>
    - 영업현금흐름이 0 이상<br>
    - 전년 신규 주식 발행 없음<br>
    (3) 점수를 모두 합치면 최저 0점, 최저 3점이 나온다. 신F-스코어가 높은 20~30종목 매수<br>
    `
  },
  debtRatio: {
    title: '부채비율',
    content: `
     부채비율 = 총부채(남의돈)/자기자본(내 돈)<br>
      자산이 100원인 기업이 자기자본 50원, 부채 50원으로 구성되어 있으면 배추비율은 50/50=100%다. 기업의 자기자본이 총자산의 3분의 2(부채는 총자산의 3분의 1)일 경우
      부채비율은 정확히 (1/3)/(2/3) = 50% 다. 물론 부채비율이 낮으면 낮을수록 부채가 적기 때문에 기업의 파산위험이 적어진다.
    `
  },
  currentRatio: {
    title: '유동비율',
    content:`
    회사가 1년 안에 현금으로 바꿀 수 있는 '유동자산'을 1년 안에 갚아야 할 '유동부채'로 나눈 값이다.<br>
    통상 유동 비율이 150% 넘으면 기업의 재무상태가 안정적이라고 평가한다.
    `
  },
  intrinsicValue: {
    title:'내재가치',
    content: `
    내재가치 = BPS + EPS * 10 (할수있다 퀀트투자, p86) 간단한 공식<br>
    내재가치를 가지고, 포트폴리오를 만들 때, 가치가중 포트폴리오를 만들 수 있다.<br>
    동일가치 포트폴리오보다 CAGR을 개선시킬 수 있다.<br>
    가치 판단이 안된다면 동일가중을 하는 것이 좋겠지. 그렇지만 난 할 수 있으니!
    `
  },
  bps: {
    title: 'BPS 주당순자산',
    content:`
    `
  },
  reserveRatio: {
    title: '유보율',
    content: `
    `
  }
}