const ReserveRatio = ({stock}) => {
  const f = stock?.finance
  const reserveRatio = f?.capitalReserveRatio
  const text = reserveRatio?.toFixed(2)

  return <span className={`${reserveRatio > 200 ? 'text-info' : 'text-danger'}`}>{text}</span>

}

export default ReserveRatio

