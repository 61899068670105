import {useGetTurtlesQuery} from "../../../../services/strategy";
import {useEffect, useState} from "react";
import TurtleItem from "./TurtleItem";
import TurtleTable from "../../Company/chart/TurtleTable";
import {Card, Col, Row} from "react-bootstrap";

const TurtleList = () => {
  const pageSize = 300
  const {data} = useGetTurtlesQuery({pageSize: pageSize})

  const [turtles, setTurtles] = useState([])
  const [buyTurtles, setBuyTurtles] = useState([])
  const [sellTurtles, setSellTurtles] = useState([])

  useEffect(() => {
    console.log('data!!!')
    data && setTurtles(data.results)
    if (data) {
      const buyTurtles = data.results.filter((stock) => stock.turtleLongSignalRealBuy)
      const sellTurtles = data.results.filter((stock) => stock.turtleLongSignalRealSell)
      setBuyTurtles(buyTurtles)
      setSellTurtles(sellTurtles)
    }
  }, [data])

  return <>
    <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>매수</Card.Title>
          </Card.Header>
          <Card.Body>
            <TurtleTable stocks={buyTurtles}/>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title>매도</Card.Title>
          </Card.Header>
          <Card.Body>
            <TurtleTable stocks={sellTurtles}/>
          </Card.Body>
        </Card>
      </Col>
    </Row>

    <Card>
      <Card.Header>
        <Card.Title>전체</Card.Title>
      </Card.Header>
      <Card.Body>
        <TurtleTable stocks={turtles}/>
      </Card.Body>
    </Card>


  </>
}

export default TurtleList