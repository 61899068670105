import {useGetStocksQuery} from "../../../../services/stock";
import StockChart from "../../Company/chart/StockChart";
import TurtleChart from "../../Company/chart/TurtleChart";
import {Card} from "react-bootstrap";
import TurtleTable from "../../Company/chart/TurtleTable";

const IndexItem = ({indexType}) => {
  const {data} = useGetStocksQuery({indexType: indexType})

  return <>
    {data?.results && <>
      <Card>
        <Card.Header>
          <Card.Title>{indexType} 지수</Card.Title>
        </Card.Header>
        <Card.Body>
          <StockChart stocks={data.results}/>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>{indexType} 지수 : 터틀 전략</Card.Title>
        </Card.Header>
        <Card.Body>
          <TurtleChart stocks={data.results}/>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>{indexType} 지수 : 터틀 전략 테이블</Card.Title>
        </Card.Header>
        <Card.Body>
          <TurtleTable stocks={data.results}/>
        </Card.Body>
      </Card>
    </>}
  </>
}

export default IndexItem