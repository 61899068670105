import {Card} from "react-bootstrap"

const MyCard = ({title, content, footer}) => {
  return <Card>
    <Card.Header>
      <Card.Title>{title}</Card.Title>
    </Card.Header>
    <Card.Body>
      {content}
    </Card.Body>
    <Card.Footer>
    </Card.Footer>
  </Card>
}

export default MyCard