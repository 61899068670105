const EV_EBITDA = ({stock}) => {
  const evEbitda = stock?.finance?.evEbitda
  const rank = stock?.rank?.evEbitda

  const Data = () => {
    const text = evEbitda?.toFixed(2)
    if (rank?.rank < 200) {
      return <span className="text-info">{text}</span>
    } else {
      return <span className="">{text}</span>
    }
  }

  const Rank = () => {
    if (rank?.rank) {
      return <small> ({rank?.rank}위, {rank?.rankRatio.toFixed(2)}%)</small>
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>
  </>
}

export default EV_EBITDA
