import {Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import MyCard from "../../../utils/MyCard";

const StrategyPage = () => {
  return (
      <Container className="container">
        <Row>
          <Col xl={4}>
            <Link to={'/guide/strategy/value'}>
              <MyCard title={'가치투자'} content={'가치투자. 재무재표를 바탕으로 종목을 선택한다. 11월에 매수하여 4월에 매도하는 전략이 좋은 것 같다.'}/>
              {/*<Card>*/}
              {/*  <Card.Header>*/}
              {/*    <Card.Title>가치투자</Card.Title>*/}
              {/*  </Card.Header>*/}
              {/*  <Card.Body>*/}
              {/*  </Card.Body>*/}
              {/*</Card>*/}
            </Link>
          </Col>
          <Col xl={4}>
            <Link to={'/guide/strategy/turtle'}>
              <Card>
                <Card.Header>
                  <Card.Title>추세추종</Card.Title>
                </Card.Header>
                <Card.Body>
                  터틀 트레이딩. 추세추종을 통해 기계적인 매매를 한다. 장기적인 수익을 기대하면서, 50~60%의 손실 매매를 하면서 큰 수익을 얻으면서 전체 수익률을 끌어올린다.
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
  )
};

export default StrategyPage;

