import React, { useState, useEffect } from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {useGetStocksQuery} from "../../../../services/stock";
import MyCard from '../../../../utils/MyCard';
import StockChart from "../../Company/chart/StockChart";
import SupplyChart from "../../Company/chart/SupplyChart";
import TurtleChart from "../../Company/chart/TurtleChart";
import TurtleTable from "../../Company/chart/TurtleTable";

const SectorChart = ({sector}) => {

  const {data} = useGetStocksQuery({sector: sector.id, market: 'all'})
  const [stocks, setStocks] = useState([])

  useEffect(() => {
    setStocks(data?.results)
  }, [data])

  return (
      <>
        <Row>
          <Col>
          <MyCard title={'주식차트'} content={stocks && <StockChart stocks={stocks}/>}/>
          </Col>
          <Col>
          <MyCard title={'수급차트'} content={stocks && <SupplyChart stocks={stocks}/>}/>
          </Col>
        </Row>
        <Row>
          <Col>
          <MyCard title={'터틀 전략'} content={
            <Row>
                  <Col xl={8}>
                    {stocks && <TurtleChart stocks={stocks}/>}
                  </Col>
                  <Col xl={4}>
                    {stocks && <TurtleTable stocks={stocks}/>}
                  </Col>
                </Row>
          }/>
          </Col>
        </Row>
      </>
  )
}
export default SectorChart;