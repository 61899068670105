import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import CompanyList from "./partials/CompanyList";
import CompanyChart from "./partials/CompanyChart";
import Select from "react-select";
import {useGetCompaniesQuery, useGetSectorsQuery, useGetThemesQuery} from "../../../services/company";

const CompanyListPage = () => {

  const {data: dataSectors} = useGetSectorsQuery()
  const {data: dataThemes} = useGetThemesQuery()
  const [optionSectors, setOptionSectors] = useState([])
  const [optionThemes, setOptionThemes] = useState([])

  useEffect(() => {
    if (!dataSectors) return
    const options = []
    for (const result of dataSectors?.results) {
      options.push({
        'value': result.id,
        'label': result.name,
      })
    }
    setOptionSectors(options)
  }, [dataSectors]);

  useEffect(() => {
    if (!dataThemes) return
    const options = []
    for (const result of dataThemes?.results) {
      options.push({
        'value': result.id,
        'label': result.name,
      })
    }
    setOptionThemes(options)
  }, [dataThemes]);

  const orderingOptions = [
    {
      'label': '시가총액',
      'value': '-market_cap',
    },
    {
      'label': '저PBR',
      'value': 'rank__pbr__rank',
    },
    {
      'label': '저PFCR',
      'value': 'rank__pfcr__rank',
    },
    {
      'label': '고GP/A',
      'value': 'rank__gp_a__rank',
    },
    {
      'label': '고NCAV',
      'value': 'rank__ncav__rank',
    },
    {
      'label': '고NCAV(%)',
      'value': 'rank__ncav_ratio__rank',
    },
    {
      'label': '저PBR+고GP/A',
      'value': 'rank__pbr_gp_a__rank',
    },
    {
      'label': '저PER, 저PBR, 저PFCR, 저PSR',
      'value': 'rank__per_pbr_pfcr_psr__rank',
    },
  ]

  const filterOption = [
    {'label': 'NCAV (유동자산-총부채) > 시가총액 * 1.5', value: 'is_nacv'},
    {'label': 'F-Score : 3', value: 'f_score'}
  ]

  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedOrdering, setSelectedOrdering] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);

  // useEffect(() => {
  //   if(selectedSector || selectedTheme || selectedOrdering || selectedFilter) {
  //
  //   }
  // }, [selectedSector, selectedTheme, selectedOrdering, selectedFilter])

  return (
      <>
        <Row>
          <Col xl={12}>
            <Card>
              {/*<Card.Header>*/}
              {/*  <Card.Title><h3>필터</h3></Card.Title>*/}
              {/*</Card.Header>*/}
              <Card.Body>
                <Row>
                  <Col>
                    <p className="mb-1">업종</p>
                    {dataSectors?.results && <Select
                        defaultValue={selectedSector}
                        onChange={setSelectedSector}
                        options={optionSectors}
                    />}
                  </Col>
                  <Col>
                    <p className="mb-1">테마</p>
                    {dataThemes?.results && <Select
                        defaultValue={selectedTheme}
                        onChange={setSelectedTheme}
                        options={optionThemes}
                    />}
                  </Col>
                  <Col>
                    <p className="mb-1">정렬</p>
                    {orderingOptions && <Select
                        defaultValue={selectedOrdering}
                        onChange={setSelectedOrdering}
                        options={orderingOptions}
                    />}
                  </Col>
                  <Col>
                    <p className="mb-1">필터</p>
                    {filterOption && <Select
                        defaultValue={selectedFilter}
                        onChange={setSelectedFilter}
                        options={filterOption}
                    />}
                  </Col>
                  <Col>
                    마켓 타이밍 전략 : 코스닥지수의 3, 5, 10일 이동평균을 계산한 후, 코스닥 지수가 이 3개 이동평균보다 낮을 경우 투자를 중단 (MDD를 엄청 줄일 수 있다)
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={12}>
            <CompanyList sector={selectedSector?.value} theme={selectedTheme?.value} ordering={selectedOrdering?.value} filter={selectedFilter?.value}/>
          </Col>
        </Row>
      </>
  )
}
export default CompanyListPage;