import React, {useContext} from "react";

/// React router dom
import {Route, Routes, useRoutes} from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import "./custom.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages
import Error404 from "./pages/Error404";

import Setting from "./layouts/Setting";
import {ThemeContext} from "../context/ThemeContext";
import CompanyListPage from "./components/Company/CompanyListPage";
import StrategyPage from "./components/Guide/StrategyPage";
import CompanyDetailPage from "./components/Company/CompanyDetailPage";
import Router from "../routes";

const Markup = () => {
  const {menuToggle} = useContext(ThemeContext);

  // const routes = [
  //   /// Dashboard
  //   {url: "", element: <Home/>},
  //   {url: "companies/", element: <CompanyListPage/>},
  //   {url: "companies/:companyCode", element: <CompanyDetailPage/>},
  //   {url: "dashboard", element: <Home/>},
  //   {url: "strategy/", element: <StrategyPage/>},
  //
  //   /// pages
  //
  //   {url: "page-error-404", component: Error404},
  // ];

  // let element = useRoutes([
  //   // Route에서 사용하는 props의 요소들과 동일
  //   {path: "/", element: <Home/>},
  //   {path: "dashboard", element: <Home/>},
  //   {
  //     path: "companies",
  //     element: <CompanyListPage/>,
  //     children: [
  //       {path: ":id", element: <CompanyDetailPage/>},
  //     ]
  //   },
  //   // NotFound 페이지는 다음과 같이 구현할 수 있음
  //   {path: "*", element: <Error404/>}
  // ]);


  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
      <>
        <div
            id={`${!pagePath ? "main-wrapper" : ""}`}
            className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""}`}
        >
          {!pagePath && <Nav/>}

          <div className={`${!pagePath ? "content-body" : ""}`}>
            <div
                className={`${!pagePath ? "container-fluid" : ""}`}
                style={{minHeight: window.screen.height - 60}}
            >
              {/*<Routes>*/}
              {/*  <Route path={""} element={<Home/>}/>*/}
              {/*  <Route path="companies/*" element={<CompanyListPage/>}>*/}
              {/*    <Route path=":companyCode" element={<CompanyDetailPage/>}/>*/}
              {/*  </Route>*/}
              {/*  <Route path={"dashboard"} element={<Home/>}/>*/}
              {/*  <Route path={"strategy"} element={<StrategyPage/>}/>*/}
              {/*  <Route path={"404"} element={<Error404/>}/>*/}
              {/*</Routes>*/}
              {/*{element}*/}
              <Router/>
            </div>
          </div>
          {!pagePath && <Footer/>}
        </div>
        <Setting/>
      </>
  );
};

export default Markup;
