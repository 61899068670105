const FScore = ({stock}) => {
  const fScore = stock?.fScore

  return <>
    <span className={`${fScore > 2 ? 'text-info' : ''}`}>{fScore}</span>
  </>
}


export default FScore
