import {Container} from "react-bootstrap";

const ValueStrategyPage = () => {
  return (
      <Container className="container">
        <div className="grid-layout grid-2-columns m-b-30" data-item="grid-item">
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 19 : 그레이엄의 마지막 선물</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초급 <span className="float-right">스타일 : 밸류 + 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>15%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건에 적합한 주식 20~30개 매수<br/>
                      - PBR 10 이하, 종목이 충분할 경우 PER 5 이하로 제한<br/>
                      - 부채비율 50% 이하
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>
                      50% 이익을 실현하면 매도<br/>
                      50% 이익을 실현 못 할 경우 매수 후 2년이 지나면 매도
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 20 : 그레이엄의 마지막 선물 업그레이드</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초급 <span className="float-right">스타일 : 밸류 + 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>15~20%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      ROA 5% 이상이고 부채비율 50% 이하인 기업중에서<br/>
                      PBR 낮은 기업부터 매수해(PBR 0.2 보다 작은 기업은 제외) 20~30개 기업 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 동일비중 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 21 : NCAV 전략</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>

                  <tr>
                    <td width="100">레벨</td>
                    <td>초급 <span className="float-right">스타일 : 밸류</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>20% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건에 부합하는 주식 매수(20~30개, 기업 수가 부족할 경우 한 개 기업에 자신의 최대 5% 만 투자)<br/>
                      - 유동자산 - 총부채 > 시가총액 x 1.5<br/>
                      - 세후이익 > 0
                      <span className="badge badge-info">NCAV</span>
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 23 : 파마 + LSV 전략</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>중급 <span className="float-right">스타일 : 소형 밸류</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>25% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - 한국에서 시가총액이 가장 작은 500개 주식을 PER, PBR, PCR별로 순위를 매김<br/>
                      - 세 개 지표의 순위를 더해서 통합 순위 작성<br/>
                      - 통합 순위가 높은 주식 50개 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
                우수한 지표 4개, 즉 소형주, 저PER, 저PBR, 저PCR의 4단 콤보.
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>계량투자의 핵심</h4>
              </div>
              <div className="card-body">
                1. 우수 지표로 추정되는 지표 발굴<br/>
                2. 다양한 백테스트를 통해 우수함을 증명(구간, 증시 변경 등)<br/>
                3. 우수 지표별로 순위를 매김<br/>
                4. 우수 지표 여러 개의 순위를 더한 통합 순위를 계산<br/>
                5. 통합 순위가 높은 주식을 매수
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 24 : 강환국 슈퍼 가치 전략</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>중급 <span className="float-right">스타일 : 소형 밸류</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>25% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - 한국 시가총액 하위 20% 주식을 대상으로 PBR, PCR, PER, PSR 각각 순위를 매김<br/>
                      - 네 개 지표의 순위를 더해 통합 순위 작성<br/>
                      - 통합 순위가 높은 종목 50개 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
                부채가 너무 많은 주식, 적자가 나는 주식, 작년에는 흑자가 났는데 그 전에는 몇 년 연속 적자 난 주식, 내가 투자하면 늘 망하는 산업에 속한주식
                (필자의 경우 금융주나 에너지주에 투자하면 재미를 못 본다.). 거래량이 너무 적어서 일정 금액을 사기 어려운 주식 등이다.
                50개 종목을 뽑은 후, 나와 정말 안맞는 종목을 무시하고, 그 중 20~30개를 고르는 것이 좋은 방법이다.
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 25 : 오리지널 및 업그레이드 마법공식 (x)</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초, 중급 <span className="float-right">스타일 : 밸류 + 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>
                      10~15% (오리지널 마법공식)<br/>
                      14~20% (업그레이드 마법공식)
                    </td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      오리지널 마법공식<br/>
                      - EV/EVIT와 ROC의 순위를 각각 매기고 더해서 통합 순위를 만든 다음,
                      통합 순위가 높은 20~30종목 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      업그레이드 마법공식
                      - EV/EVIT와 GP/A의 순위를 각각 매기고 더해서 통합 순위를 만든 다음,
                      통합 순위가 높은 20~30종목 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 26 : 신마법공식 1.0(저PBR + 고GP/A)</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초 <span className="float-right">스타일 : 밸류 + 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>약 15~20%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - 모든 주식의 PBR, GP/A 순위 계산<br/>
                      - 2개 순위를 더한 통합 순위가 가장 높은 종목 30개 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
                꼭 새겨둬야 할 점은, 포트폴리오 자체는 장기적으로 상승해도 모든 종목들이 오르는 것은 절대 아니라는 것이다.
                이 책에서 제안하는 전략을 따르면 모든 종목이 사이좋게 20~30% 오른다고 상상할지 모른다. 큰 착각이다. 절대 그런 일은 없다.
                몇몇 주식이 몇백 % 상승해서 전체 포트폴리오 수익이 아름답게 변하는 것이다.
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 27 : 신마법공식 2.0(저PBR + 고GP/A + 소형주)</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초, 중급<span className="float-right">스타일 : 밸류 + 퀄리티 + 소형주</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>20% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - 모든 주식의 PBR, GP/A 순위 계산<br/>
                      - 2개 순위를 더한 통합 순위가 가장 높은 종목 30개 매수<br/>
                      - 소형주 (시가총액 하위 20%) 기업만 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
                상위 50위 안에 선정된 700개 종목 중에서 플러스 수익을 낸 주식은 419개(59.9%). 전체 종목의 40%는 마이너스.
                15개는 상장폐지.
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 28 : 오리지널 F-스코어</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>중급<span className="float-right">스타일 : 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>20% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - PBR 최저 20% 기업에만 투자<br/>
                      - F-스코어 9개 요소를 검토해 맞으면 1점, 틀리면 0점을 매긴 후,
                      점수가 높은 20~30개 기업 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 29 : 신F-스코어 + 저PBR</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>왕초보<span className="float-right">스타일 : 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>20% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      (1) PBR 최저 20% 기업에만 투자<br/>
                      (2) 아래의 3개 요소를 분석해서, 맞으면 1점, 틀리면 0점을 배분<br/>
                      - 수익이 0 이상<br/>
                      - 영업현금흐름이 0 이상<br/>
                      - 전년 신규 주식 발행 없음<br/>
                      (3) 점수를 모두 합치면 최저 0점, 최저 3점이 나온다. 신F-스코어가 높은 20~30종목 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 30 : 강환국 슈퍼 퀄리티 전략 1.0</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초, 중급<span className="float-right">스타일 : 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>약 20%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - 신F-스코어 3점 종목만 매수<br/>
                      - 여기에 GP/A 순위를 부여, 순위 높은 20~30 종목을 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 31 : 강환국 슈퍼 퀄리티 전략 2.0</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초, 중급<span className="float-right">스타일 : 퀄리티</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>약 20% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건을 만족하는 20~30종목 매수<br/>
                      - 신F-스코어 3점 종목만 매수<br/>
                      - 여기에 GP/A 순위를 부여, 순위 높은 20~30 종목을 매수<br/>
                      - 단, 소형주(시가총액 최저 20%)만 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <h4 className="heading-text">모멘텀 전략</h4>
        <div className="grid-layout grid-2-columns m-b-30" data-item="grid-item">
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 32 : 한국 대형주 모멘텀 전략</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초급 <span className="float-right">스타일 : 모멘텀</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>10%~15%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      대형주(시가총액 최고 200위) 중 최근 12개월 수익률이 가장 높은 40종목에 투자
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 또는 분기 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
                신기하게도 한국은 상대적 모멘텀 전략이 대형주에서만 알파를 창출했고, 중형 및 소형주에서는 주가지수를 이기지 못했다.
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 33 : 대형주 밸류 + 모멘텀 전략</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>초급 <span className="float-right">스타일 : 밸류 + 모멘텀</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>15%~20%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      - 국내 시가총액 상위 200개 종목 중 밸류 콤보가 가장 높은 75개 발굴<br/>
                      - 밸류 콤보 : PBR, PCR, PER, PSR, EV/EBITDA, 배당수익률의 통합 순위 중 12개월 과거 수익이 가장 높은 25종목 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 34 : 한국 대형주 '짬뽕' 전략</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>고급 <span className="float-right">스타일 : 밸류 + 퀄리티 + 모멘텀</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>15%~20%</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건을 충족하는 종목 찾기<br/>
                      - 순이익, 자본, 영업현금흐름, EBITDA, 배당 > 0 및 증자 없는 기업만 사용<br/>
                      - 퀄리티 순위 계산(GP/A)<br/>
                      - 밸류 콤보 순위 계산(PER+PBR+PCR+PCR+PSR+EV/EBITDA+배당수익률)<br/>
                      - 모멘텀 순위 계산(12개월 과거 수익률 순위)<br/>
                      - 순위 비중 : 밸류 콤보 33%, 퀄리티 콤보 33%, 모멘텀 33%<br/>
                      - 통합 순위가 가장 높은 주식 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>연 1회 리밸런싱</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 35 : 슈퍼 밸류 모멘텀 전략 1.0</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>고급 <span className="float-right">스타일 : 밸류 + 모멘텀</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>25% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건을 충족하는 종목 찾기<br/>
                      - 소형주(시가총액 최하 20%) 주식에만 투자<br/>
                      - PBR, PSR, PER, GP/A의 각 순위를 계산<br/>
                      - 4개 지표의 통합 순위가 가장 높은 50개 종목 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>
                      - 50종목 중 12개월 과거 수익이 0 이상인 종목만 보유<br/>
                      - 50종목 중 12개월 과거 수익이 0 이하인 종목은 현금화, 현금을 과거 수익 0 이상인 종목에 재투자<br/>
                      - 12개월 과거 수익이 0 이상인 종목이 4분의 1 이하이면 전 종목 매도, 현금 보유<br/>
                      - 월 1회 리밸런싱
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 36 : 슈퍼 밸류 모멘텀 전략 2.0</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>고급 <span className="float-right">스타일 : 밸류 + 모멘텀</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>20% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건을 충족하는 종목 찾기<br/>
                      - 소형주(시가총액 최하 20%) 주식에만 투자<br/>
                      - PBR, PSR, PER, GP/A의 각 순위를 계산<br/>
                      - 4개 지표의 통합 순위가 가장 높은 50개 종목 매수
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>
                      - 50종목 중 12개월 과거 수익이 0 이상인 종목만 보유<br/>
                      - 50종목 중 12개월 과거 수익이 0 이하인 종목은 현금화, 현금은 12개월 과거 수익이 0 이상으로 올라갈 때까지 보유 <br/>
                      - 12개월 과거 수익이 0 이상인 종목이 4분의 1 이하이면 전 종목 매도, 현금 보유<br/>
                      - 월 1회 리밸런싱
                    </td>
                  </tr>
                  </tbody>
                </table>
                류쥬현의 결론<br/>
                (1) 시가총액 하위 20%, 즉 소형주 전략은 아주 우월하다.<br/>
                (2) 밸류 콤보 전략은 국내 시장에서 아주 강한 위력을 보인다.<br/>
                (3) 절대적 모멘텀 전략을 가미해 더 안전하게 소형주+밸류 콤보 전략을 구할 수 있다.
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>투자 전략 37 : 슈퍼스타 K</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">레벨</td>
                    <td>고급 <span className="float-right">스타일 : 밸류 + 모멘텀</span></td>
                  </tr>
                  <tr>
                    <td>기대 CAGR</td>
                    <td>25% 이상</td>
                  </tr>
                  <tr>
                    <td>매수 전략</td>
                    <td>
                      아래 조건을 충족하는 종목 찾기<br/>
                      - 유동비율이 100% 이상인 종목만 포함<br/>
                      - PBR, PSR, PER, PCR의 각 순위를 계산<br/>
                      - 4개의 지표의 통합 순위가 가장 높은 30종목 매수<br/>
                      - 소형주(시가총액 하위 20%)만 매수<br/>
                      - 개별 종목 비중을 '평균 모멘텀 스코어'로 결정!
                    </td>
                  </tr>
                  <tr>
                    <td>매도 전략</td>
                    <td>
                      - 50종목 중 12개월 과거 수익이 0 이상인 종목만 보유<br/>
                      - 50종목 중 12개월 과거 수익이 0 이하인 종목은 현금화, 현금은 12개월 과거 수익이 0 이상으로 올라갈 때까지 보유 <br/>
                      - 12개월 과거 수익이 0 이상인 종목이 4분의 1 이하이면 전 종목 매도, 현금 보유<br/>
                      - 월 1회 리밸런싱
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div className="card card-custom">
                  <div className="card-header">
                    <h4>평균 모멘텀 스토어</h4>
                  </div>
                  <div className="card-body">
                    (1) 모멘텀 전략을 실시할 때 최근 1, 2, 3, ..12개월의 과거 수익을 각각 구한다.<br/>
                    (2) 각 과거 수익이 0 이상이면 1점, 0 이하면 0점을 부여한다.<br/>
                    (3) 이를 합하면 0~12점이 나온다. 이 총점을 12로 나눈다.<br/>
                    (4) 총점/12가 당월의 주식 투자 비중이고, 매월 이 비율로 '주식 대 현금' <br/>
                    또는 '주식 대 채권' 비중을 조절해 투자한다.<br/>
                    <br/>
                    따라서 상승세가 아주 강력할 경우 스코어가 1에 가까워지므로 더 많은 돈을 주식에 투자하게 된다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="heading-text">모멘텀 전략</h4>
        <div className="grid-layout grid-2-columns m-b-30" data-item="grid-item">
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>CAGR이 높은 전략을 개발하는 방법</h4>
              </div>
              <div className="card-body">
                1. CAGR이 주가지수보다 높은 우수한 개별 지표 찾기<br/>
                2. 우수한 개별 지표 및 몇 개를 합친 콤보 지표 만들기<br/>
                3. 20~30종목 분산투자 및 주기적 리밸런싱
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>알파를 창출하는 개별 지표들</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">요소</td>
                    <td>설명</td>
                  </tr>
                  <tr>
                    <td>소형주</td>
                    <td>소형주가 중형, 대형주보다 CAGR이 높다.</td>
                  </tr>
                  <tr>
                    <td>밸류</td>
                    <td>
                      PER, PBR, PCR, PSR, EV/EBIT, EV/EBITDA, 배당수익률, NCAV
                    </td>
                  </tr>
                  <tr>
                    <td>퀄리티</td>
                    <td>GP/A, 순이익 > 0, 영업현금흐름 > 0, 증자여부</td>
                  </tr>
                  <tr>
                    <td>모멘텀</td>
                    <td>과거 3~12개월 주가 상승률</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid-item">
            <div className="card card-custom">
              <div className="card-header">
                <h4>콤보 지표 정리</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-vertical-center">
                  <tbody>
                  <tr>
                    <td width="100">대구분</td>
                    <td>소구분</td>
                    <td>설명</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">밸류 콤버보</td>
                    <td>파마+LSV 콤보</td>
                    <td>저PER + 저PBR + 저PCR + 소형주</td>
                  </tr>
                  <tr>
                    <td>강환국 슈퍼 가치 전략</td>
                    <td>저PER + 저PBR + 저PCR + 저PCR + 소형주</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">퀄리티 콤보</td>
                    <td>파산기업 피하기</td>
                    <td>저부채비율 + 고ROA</td>
                  </tr>
                  <tr>
                    <td>강환국 슈퍼 퀄리티 전략</td>
                    <td>신F-스코어 + GP/A + 소형주</td>
                  </tr>
                  <tr>
                    <td>모멘텀 콤보</td>
                    <td>듀얼 모멘텀</td>
                    <td>절대적 모멘텀 + 상대적 모멘텀</td>
                  </tr>
                  <tr>
                    <td rowSpan="5">밸류 퀄리티 콤보</td>
                    <td>그레이엄의 마지막 선물</td>
                    <td>저PER + 저부채비율</td>
                  </tr>
                  <tr>
                    <td>마법공식</td>
                    <td>저EV/EBIT + 고ROC</td>
                  </tr>
                  <tr>
                    <td>신마법공식</td>
                    <td>저PBR + 고GP/A</td>
                  </tr>
                  <tr>
                    <td>F-스코어</td>
                    <td>9개의 재무 지표로 구성된 콤보 전략</td>
                  </tr>
                  <tr>
                    <td>신F-스코어</td>
                    <td>위의 9개 지표 중 순이익 > 0, 영업현금흐름 > 0, 증자 여부 3개 지표만 확인하는 단순 F-스코어</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">밸류 모멘텀 콤보</td>
                    <td>오쇼너시 밸류 + 모멘텀</td>
                    <td>고밸류 콤보 순위 + 고모멘텀</td>
                  </tr>
                  <tr>
                    <td>슈퍼스타 K</td>
                    <td>강환국 슈퍼 가치 전략 + 평균 모멘텀 스코어 + 유동비율</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">밸류 + 퀄리티 + 모멘텀 콤버</td>
                    <td>애스네스 짬뽕 전략</td>
                    <td>밸류 + 퀄리티 + 모멘텀 통합 순위 구성</td>
                  </tr>
                  <tr>
                    <td>슈퍼 가치 모멘텀 전략</td>
                    <td>저PER + 저PBR + 저PSR + 고GP/A + 절대적 모멘텀</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
  )
};

export default ValueStrategyPage;

