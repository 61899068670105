import React from "react";
import {Bar, Line} from "react-chartjs-2";
import _ from 'lodash'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PrimaryColor, {Colors} from "../../../../utils/Color";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CompanyAndSectorChart = ({companyStocks, sectorStocks}) => {
  const filtered = companyStocks.filter((stock) => stock.date);
  const ordered = _.orderBy(filtered, ['date'], ['asc'])

  const sectorFiltered = sectorStocks.filter((stock) => stock.date);
  const sectorOrdered = _.orderBy(sectorFiltered, ['date'], ['asc'])

  const data = {
    defaultFontFamily: "Poppins",
    labels: ordered.map(({date}) => date),
    datasets: [
      {
        label: `${ordered.length > 0 ? ordered[0].company?.name : ''} 주가`,
        data: ordered.map(({close}) => close),
        borderColor: Colors.black,
        borderWidth: 2,
        // backgroundColor: "#886cc0",
        backgroundColor: Colors.black,
        pointRadius: 0,
        yAxisID: 'y',
      },
      {
        label: `${sectorOrdered.length > 0 ? sectorOrdered[0]?.sector?.name : ''} 주가`,
        data: sectorOrdered.map(({close}) => close),
        borderColor: Colors.orange,
        borderWidth: 2,
        // backgroundColor: "#886cc0",
        backgroundColor: Colors.orange,
        pointRadius: 0,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    legend: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    layout: {
      padding: {
        right: 0
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      y1: {
        // min: 1,
        // max: 2,
        // display: false,
        position: 'right',
      }
    },

  };

  return (
      <>
        <Line options={options} data={data} height={150}/>
      </>
  );
}

export default CompanyAndSectorChart;
