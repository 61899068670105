import _ from "lodash";

const GrowthROE = ({stock}) => {
  const finances = stock?.finances
  const filtered = finances?.filter((finance) => finance.type === 'quarter')
  const ordered = _.orderBy(filtered, ['year', 'month'], ['asc'])

  return <>
    <div className="d-flex">
      {ordered?.map((finance) => (
          <div className={`mx-2 text-center ${finance.roe > 10 ? 'text-info' : ''}`}>
            <small>{finance.year}/{finance.month}</small><br/>
            <span className="fs-15">{finance.roe ?? '-'}</span>
          </div>
      ))}
    </div>
  </>
}

export default GrowthROE