import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const strategyApi = createApi({
  reducerPath: 'strategyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // prepareHeaders: (headers, {getState}) => {
    //   const accessToken = getState().user.accessToken
    //   if (accessToken) {
    //     headers.set('Authorization', `Bearer ${accessToken}`)
    //   }
    //   return headers
    // },
  }),
  tagTypes: ['Strategy',],
  // refetchOnMountOrArgChange: 1,
  endpoints: (build) => ({
    getTurtles: build.query({
      query: ({pageSize}) => {
        return {
          url: `strategy/turtle/`,
          params: {
            page_size: pageSize ? pageSize : '224',
          }
        }
      }
    }),
  }),
})

export const {
  useGetTurtlesQuery,
} = strategyApi