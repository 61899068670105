import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
//import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import ScrollToTop from "./utils/ScrollTop";

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <ThemeContext>
            <ScrollToTop/>
            <App/>
          </ThemeContext>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
