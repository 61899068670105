import {applyMiddleware, combineReducers, compose, createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import {AuthReducer} from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import {reducer as reduxFormReducer} from 'redux-form';
import {companyApi} from "../services/company";

import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {stockApi} from "../services/stock";
import selectedReducer from './features/selectedSlice';
import {strategyApi} from "../services/strategy";
// const middleware = applyMiddleware(thunk);
//
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const reducers = combineReducers({
//   posts: PostsReducer,
//   auth: AuthReducer,
//   todoReducers,
//   form: reduxFormReducer,
//   [companyApi.reducerPath]: companyApi.reducer,
//
// });
//
// //const store = createStore(rootReducers);
//
// export const store = createStore(reducers, composeEnhancers(middleware));


export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    auth: AuthReducer,
    // user: userReducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [stockApi.reducerPath]: stockApi.reducer,
    [strategyApi.reducerPath]: strategyApi.reducer,
    selected: selectedReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
          companyApi.middleware,
          stockApi.middleware,
          strategyApi.middleware,
      ),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)