import {lazy, Suspense, useEffect} from 'react';

/// Components
import Index from "./jsx";
import {connect, useDispatch} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
// action
import {checkAutoLogin} from './services/AuthService';
import {isAuthenticated} from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import Router from "./routes";


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function App(props) {
  const dispatch = useDispatch();
  const {navigate} = useNavigate();


  //  useEffect(() => {
  //     navigate('/companies')
  // }, [dispatch]);

  // useEffect(() => {
  //     checkAutoLogin(dispatch, props.history);
  // }, [dispatch, props.history]);

  let routes = (
      <Routes>
        {/*<Route path='/login' component={Login}/>*/}
        {/*<Route path='/page-register' component={SignUp}/>*/}
        {/*<Route path='/page-forgot-password' component={ForgotPassword}/>       */}
        <Route path='/login' element={Login}/>
        <Route path='/page-register' element={SignUp}/>
        <Route path='/page-forgot-password' element={ForgotPassword}/>
      </Routes>
  );
  if (props.isAuthenticated) {
    return (
        <>
          <Suspense fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
          >
            <Index/>
          </Suspense>
        </>
    );

  } else {
    return (
        <div className="vh-100">
          <Suspense fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
          >
            {/*<Router/>*/}
            {/*{routes}*/}
            <Index/>
          </Suspense>
        </div>
    );
  }
};

// const mapStateToProps = (state) => {
//     return {
//         isAuthenticated: isAuthenticated(state),
//     };
// };
//
// export default withRouter(connect(mapStateToProps)(App));
export default App;

