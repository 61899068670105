const POR = ({stock}) => {
  const por = stock?.por

  const Data = () => {
    const text = por?.toFixed(2)
    if (por <= 2 || por > 8) {
      return <span className=" text-danger">{text}</span>
    } else {
      return <span className="text-info">{text}</span>
    }
  }
  return <><Data/></>
}

export default POR
