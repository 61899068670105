const ROA = ({stock}) => {
  const f = stock?.finance
  const roa = f?.roa

  const Data = () => {
    const text = roa?.toFixed(2)
    if (roa >= 5) {
      return <span className="text-info">{text}</span>
    }
    return <span>{text}</span>
  }
  return <>
    <Data/>
  </>
}

export default ROA

// {% if roa >= 5 %}
//     <span class="text-info">{{ roa|floatformat:0 }}</span>
// {% else %}
//     {{ roa|floatformat:0 }}
// {% endif %}
