import React from "react";
import {Bar, Line} from "react-chartjs-2";
import _ from 'lodash'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import PrimaryColor, {Colors} from "../../../../utils/Color";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TurtleChart = ({stocks}) => {
  const ordered = _.orderBy(stocks, ['date'], ['asc'])

  const pointStyles = (ctx) => {
    const index = ctx.dataIndex
    const stock = ordered[index]
    if (stock?.turtleLongSignalBuy) return 'triangle'
    if (stock?.turtleLongSignalSell) return 'rect'
    if (stock?.isBreakthroughUpperLine20) return 'circle'
    if (stock?.isBreakthroughLowerLine10) return 'circle'
    return 'rect';
  }
  const pointRadius = (ctx) => {
    const index = ctx.dataIndex
    const stock = ordered[index]
    if (stock?.turtleLongSignalRealBuy) return 8
    if (stock?.turtleLongSignalBuy) return 6
    if (stock?.turtleLongSignalSell) return 6
    if (stock?.turtleLongSignalStopLoss) return 10
    // if (stock.isBreakthroughUpperLine20) return 2
    // if (stock.isBreakthroughLowerLine10) return 2
    return 0
  }

  const backgroundColors = (ctx) => {
    const index = ctx.dataIndex
    const stock = ordered[index]
    if (!stock) return Colors.black
    if (stock.turtleLongSignalBuy) return Colors.red
    if (stock.turtleLongSignalSell) return Colors.blue
    if (stock.isBreakthroughUpperLine20) return Colors.red
    if (stock.isBreakthroughLowerLine10) return Colors.blue
    return Colors.black
  }

  const data = {
    defaultFontFamily: "Poppins",
    labels: ordered.map(({date}) => date),
    datasets: [
      {
        label: "주가",
        data: ordered.map(({close}) => close),
        borderColor: Colors.black,
        spanGaps: true,
        borderWidth: 2,
        // backgroundColor: Colors.black,
        // pointRadius: 2,
        backgroundColor: backgroundColors,
        pointStyle: pointStyles,
        pointRadius: pointRadius,
        yAxisID: 'y',
      },
      {
        label: "매수지점",
        data: ordered.map(({turtleTrend}) => turtleTrend === 'long' ? 1 : null),
        borderColor: Colors.red,
        borderWidth: 5,
        yAxisID: 'y1',
      },
      {
        label: "4주 상단채널",
        data: ordered.map(({upperLine20}) => upperLine20),
        borderColor: Colors.red,
        borderWidth: 1,
        backgroundColor: Colors.red,
        pointStyle: 'circle',
        pointRadius: ordered.map(({isBreakthroughUpperLine20}) => isBreakthroughUpperLine20 ? 2 : 0),
        fill: true,
        yAxisID: 'y',
      },
      {
        label: "2주 하단채널",
        data: ordered.map(({lowerLine10}) => lowerLine10),
        borderColor: Colors.blue,
        borderWidth: 1,
        backgroundColor: Colors.blue,
        pointStyle: 'circle',
        pointRadius: ordered.map(({isBreakthroughLowerLine10}) => isBreakthroughLowerLine10 ? 2 : 0),
        fill: true,
        yAxisID: 'y',
      },
      {
        label: "224일 이동평균선",
        data: ordered.map(({sma224}) => sma224),
        borderColor: Colors.gray,
        borderWidth: 1,
        backgroundColor: Colors.gray,
        pointRadius: 0,
        yAxisID: 'y',
      },
      {
        label: "손절가격",
        data: ordered.map(({turtleLongStopLossPrice}) => turtleLongStopLossPrice),
        borderColor: Colors.maroon,
        borderWidth: 2,
        backgroundColor: Colors.gray,
        pointRadius: 0,
        yAxisID: 'y',
      },
      // {
      //   label: "손절시그널",
      //   data: ordered.map(({turtleLongSignalStopLoss}) => turtleLongSignalStopLoss),
      //   borderColor: Colors.red,
      //   borderWidth: 2,
      //   backgroundColor: Colors.gray,
      //   pointRadius: 10,
      //   yAxisID: 'y',
      //   pointStyle: 'circle',
      // },
    ],
  };

  // function getLineColor(ctx) {
  //   return Utils.color(ctx.datasetIndex);
  // }

  function alternatePointStyles(ctx) {
    const index = ctx.dataIndex;
    return stocks[index]?.turtleLongSignalBuy ? 'circle' : 'rect';
  }

  function alternatePointRadius(ctx) {
    const index = ctx.dataIndex;
    // if(stocks[index].turtleLongSignalBuy) {
    //   console.log('alternatePointStyles', index, stocks[index].date)
    // } else {
    //   console.log('alternatePointStyles No', index, stocks[index].date)
    // }
    return stocks[index]?.turtleLongSignalBuy ? 3 : 0;
  }

  const options = {
    responsive: true,
    legend: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      tooltip: true,
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      // tooltip: {
      //   callbacks: {
      //     footer: (items) => {
      //       return 'ddd'
      //     },
      //   }
      // }
    },
    elements: {
      line: {
        fill: false,
        // backgroundColor: getLineColor,
        // borderColor: getLineColor,
      },
      point: {
        backgroundColor: Colors.orange,
        // hoverBackgroundColor: makeHalfAsOpaque,
        // radius: adjustRadiusBasedOnData,
        pointStyle: alternatePointStyles,
        pointRadius: alternatePointRadius,
        hoverRadius: 5,
      }
    },
    scales: {
      y1: {
        min: 1,
        max: 2,
        display: false,
        position: 'right',
      }
    },
    // scales: {
    //   yAxes: [
    //     {
    //       type: 'linear',
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    //   xAxes: [
    //     {
    //       type: 'linear',
    //       barPercentage: 0.5,
    //     },
    //   ],
    // },
  };

  return (
      <>
        <Line options={options} data={data} height={150}/>
      </>
  );
}

export default TurtleChart;
