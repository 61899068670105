const PCR = ({stock}) => {
  const f = stock.finance
  const pcr = stock?.pcr
  const pcrRank = stock?.rank?.pcr

  const Data = () => {
    const text = pcr?.toFixed(2)
    if (pcr < 0 || pcr >= 40) {
      return <span className="text-danger">{text} </span>
    } else if (pcr >= 1 && pcr <= 8) {
      return <span className="text-info">{text} </span>
    } else {
      return <span>{text} </span>
    }
  }

  const Rank = () => {
    if (pcrRank) {
      return <small>({pcrRank?.rank}위, <span className={`${pcrRank?.rankRatio <= 20 ? 'text-info' : ''}`}>{pcrRank?.rankRatio?.toFixed(2)}%</span>)</small>
    }
    return <></>
  }

  const CashFlow = () => {
    if (stock && f && stock?.marketCap / 100000000 > f?.cashFlowsFromOperating * 1.3) {
      if (stock?.marketCap / 100000000 < f?.cashFlowsFromOperating * 1.7) {
        return <span className="text-info">GOOD</span>
      }
    }
    return <></>
  }

  return <>
    <Data/>
    <Rank/>
    <CashFlow/>
  </>
}

export default PCR
