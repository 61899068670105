const CurrentRatio = ({stock}) => {
  const f = stock?.finance
  const currentRatio = f?.currentRatio

  return <>
    <span className={`${currentRatio > 100 ? 'text-info' : ''}`}>{currentRatio?.toFixed(2)}</span>
  </>
}


export default CurrentRatio

