import React from 'react';

const Home = () => {
	return(
		<>
			<div className="">
				<h3>Home Page</h3>
			</div>	
		</>
	)
}
export default Home;